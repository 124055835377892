import React from 'react';
import {
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  AuthProvider,
  useFirebaseApp,
} from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

function FirebaseSDKProviders({ children }) {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <FunctionsProvider sdk={functions}>
          <StorageProvider sdk={storage}>{children}</StorageProvider>
        </FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default FirebaseSDKProviders;
