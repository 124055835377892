import React, { FC } from 'react';
import { useField } from 'formik';
import TextField from '../TextField';

type FormikTextFieldProps = TextFieldProps & {
  name: string;
};

const FormikTextField: FC<FormikTextFieldProps> = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  return (
    <TextField
      {...rest}
      {...field}
      error={touched && Boolean(error)}
      helperText={touched ? error : ''}
    />
  );
};

export default FormikTextField;
