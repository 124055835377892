import React, { FC, useState, useEffect } from 'react';
import { doc, collection, updateDoc } from 'firebase/firestore';
import { useFormikContext } from 'formik';
import { useFirestore } from 'reactfire';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useAuthContext } from '../../context/AuthContext';
import useCountdownTimer from '../../hooks/useCountdownTimer';
import { convertLocalDateTimeToISO } from '../../utils/dateUtils';

type SubmissionCountdownProps = {
  record: Submission;
};

const SubmissionCountdown: FC<SubmissionCountdownProps> = ({ record }) => {
  const db = useFirestore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { userData } = useAuthContext();
  const { submitForm, setFieldValue } = useFormikContext();
  const { lockDateTime, lockedBy, isLocked } = record;
  const { time } = useCountdownTimer({
    expiresAt: DateTime.fromISO(lockDateTime as string)
      .setZone(DateTime.local().zoneName)
      .plus({ minutes: 12 })
      .toMillis(),
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isLockedOpen, setIsLockedOpen] = useState(false);

  useEffect(() => {
    let timer: any;

    if (isLocked && userData?.displayName !== lockedBy) {
      setIsLockedOpen(true);
      timer = setTimeout(() => {
        navigate(`/submission/${id}`);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [userData, isLocked, lockedBy, navigate, id]);

  useEffect(() => {
    async function clearEdits() {
      const docRef = doc(collection(db, 'submissions'), record.id);
      await updateDoc(docRef, {
        lockDateTime: null,
        isLocked: false,
        lockedBy: null,
      });
      navigate(`/submission/${id}`);
    }

    if (time <= 120) {
      setIsOpen(true);
    }

    if (time === 0) {
      clearEdits();
    }
  }, [db, navigate, record, time, id]);

  return (
    <>
      <Dialog open={isLockedOpen} maxWidth="sm" scroll="paper" fullWidth>
        <DialogTitle color="status.primary">Submission Locked</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This submission is currently being edited by {lockedBy}.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '10px 24px 20px 24px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => navigate(-1)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
        <DialogTitle color="status.primary">Continue Editing?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Do you want to continue editing this submission? If you do not
            confirm within 2 minutes your changes will be discarded.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '10px 24px 20px 24px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={async () => {
              const nowDT = DateTime.local();
              setFieldValue('lockDateTime', convertLocalDateTimeToISO(nowDT));
              await submitForm();
              setIsOpen(false);
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmissionCountdown;
