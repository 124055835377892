import React, { FC, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { nanoid } from 'nanoid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { FormMode } from '../../shared/enums';
import { useSubmissionFilesContext } from '../../context/SubmissionFilesContext';

import UploadInput from '../UploadInput';

interface MapOfArray {
  [key: string]: string[];
}

export interface UploadInputWrapperProps {
  setFiles: (files: File[]) => void;
  files: File[];
  disabled: boolean;
  mode?: FormMode;
}

const UploadInputWrapper: FC<UploadInputWrapperProps> = ({
  setFiles,
  files,
  disabled,
  mode,
}) => {
  const { files: uploadedFileList } = useSubmissionFilesContext();
  const [error, setError] = useState('');
  const accept: MapOfArray = { 'application/pdf': ['.pdf'] };
  const maxSize = 20971520; // 20 MB

  const uploadFileDropzoneOptions: DropzoneOptions = {
    accept,
    multiple: true,
    maxSize,
    disabled,
    onDropAccepted: (f) => {
      const newFiles = f
        .filter((it) => {
          // filter out duplicates due to drag n drop
          const isDuplicate = files.find((fil) => fil.name === it.name);
          return !isDuplicate;
        })
        .map((fil) => {
          // In edit mode and if filename already exists, rename with a random id to avoid file name collisions
          const exists =
            mode === FormMode.CREATE
              ? false
              : uploadedFileList.find((it) => it.name === fil.name);

          if (exists) {
            const ext = fil.name.substring(fil.name.lastIndexOf('.'));
            const nameOnly = fil.name.substring(0, fil.name.lastIndexOf('.'));

            return new File([fil], `${nameOnly}-${nanoid(5)}${ext}`, {
              type: fil.type,
              lastModified: fil.lastModified,
            });
          }
          return fil;
        });

      setFiles(files.concat(newFiles));
      setError('');
    },
    onDropRejected: (fileRejections) => {
      // very simple error handling because we have simple requirements. would need to expand if alternative requirements
      // develop.
      if (fileRejections.length) {
        setError(
          `Only ${Object.keys(accept)
            .map((it) => accept[it])
            .reduce((acc, nx) => acc.concat(nx), [])
            .join(', ')} files up to ${maxSize / 1024 / 1024} MB are allowed`
        );
      }
    },
    onFileDialogOpen: () => {
      setError('');
    },
  };

  return (
    <>
      <Box sx={{ paddingBottom: '24px' }}>
        {!!error && <Alert severity="error">{error}</Alert>}
      </Box>
      <UploadInput
        files={files}
        setFiles={setFiles}
        uploadInputId="files"
        uploadInputTitle="Click here or drag and drop files to upload"
        dropZoneOptions={uploadFileDropzoneOptions}
      />
    </>
  );
};

export default UploadInputWrapper;
