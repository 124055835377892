/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { httpsCallableFromURL } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { useAuthContext } from '../../context/AuthContext';
import { useStaffContext } from '../../context/StaffContext';
import { useTenantContext } from '../../context/TenantContext';
import Wrapper from '../../components/Layout/Wrapper';
import Modal from '../../components/Modal';
import PageActions from '../../components/PageElements/PageActions';
import CreateStaffModal from '../../components/Modal/CreateStaffModal';
import UpdateStaffModal from '../../components/Modal/UpdateStaffModal';
import Loading from '../../components/Loading';
import { formatNumber } from '../../utils/format';

const { REACT_APP_DEL_USER_FN_NAME, REACT_APP_REINV_USER_FN_NAME } =
  process.env;

function EditCell({ rowData }) {
  const [isOpen, setIsOpen] = useState(false);

  const { row } = rowData;

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        Edit
      </Button>
      <UpdateStaffModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        record={row}
        key={row.id}
      />
    </>
  );
}

function ReinviteCell({ rowData }) {
  const fns = useFunctions();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { row } = rowData;

  const fn = httpsCallableFromURL(fns, REACT_APP_REINV_USER_FN_NAME);

  const content = useMemo(() => {
    if (isLoading) return <Loading />;
    if (error)
      return (
        <Typography component="h4" variant="h4" color="error">
          {error}
        </Typography>
      );
    return (
      <Typography variant="body1">
        {row.lastName}, {row.firstName} has already been invited to the Broker
        Portal, are you sure you want to invite them again?
      </Typography>
    );
  }, [row, error, isLoading]);

  const handleConfirm = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    try {
      const result = await fn({ id: row.id });
      if (result && result?.data?.error) {
        setError(result.data.error);
        setIsLoading(false);
      } else {
        setIsOpen(false);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, [fn, row]);

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        Resend Invite
      </Button>
      <Modal
        title="Resend Invite"
        content={content}
        cancelText="Cancel"
        confirmText="Resend Invite"
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onCancel={() => setIsOpen(false)}
        isDisabled={isLoading}
        maxWidth="xs"
      />
    </>
  );
}

function DeleteCell({ rowData }) {
  const fns = useFunctions();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { row } = rowData;

  const fn = httpsCallableFromURL(fns, REACT_APP_DEL_USER_FN_NAME);

  const content = useMemo(() => {
    if (isLoading) return <Loading />;
    if (error)
      return (
        <Typography component="h4" variant="h4" color="error">
          {error}
        </Typography>
      );
    return (
      <Typography variant="body1" color="status.error">
        Are you sure you want to remove {row.lastName}, {row.firstName}&apos;s
        ability to access Emportal Connect?
      </Typography>
    );
  }, [row, error, isLoading]);

  const handleConfirm = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    try {
      const result = await fn({ id: row.id });
      if (result && result?.data?.error) {
        setError(result.data.error);
        setIsLoading(false);
      } else {
        setIsOpen(false);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, [fn, row]);

  return (
    <>
      <Button
        variant="text"
        color="error"
        disableElevation
        onClick={() => setIsOpen(true)}
      >
        Remove Access
      </Button>
      <Modal
        title="Remove Access"
        content={content}
        cancelText="Cancel"
        confirmText="Remove Access"
        isOpen={isOpen}
        status="error"
        onConfirm={handleConfirm}
        onCancel={() => setIsOpen(false)}
        maxWidth="xs"
      />
    </>
  );
}

function StaffList() {
  const {
    claims: { admin },
    isAuthStateLoaded,
  } = useAuthContext();

  const { staff, isLoading } = useStaffContext();
  const { tenants } = useTenantContext();

  const rows = useMemo(() => (isLoading ? [] : staff), [staff, isLoading]);

  const [addOpen, setAddOpen] = useState(false);
  const handleAddOpen = () => setAddOpen(true);

  const cols = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: false,
      renderCell: (val) => `${val.row.lastName}, ${val.row.firstName}`,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      editable: false,
      renderCell: (val) => {
        const fmt = formatNumber(val.row.phone);
        return <>{fmt.formatted}</>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.2,
      editable: false,
      renderCell: (val) => `${val.row.email}`,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      editable: false,
      renderCell: (val) =>
        tenants.find((it) => it.id === val.row.tenantId)?.name ?? 'N/A',
    },
    {
      field: 'removeAccessButton',
      headerName: '',
      minWidth: 200,
      maxWidth: 200,
      renderCell: (val) => <DeleteCell rowData={val} />,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',
      headerClassName: 'table-heading--empty',
    },
    {
      field: 'resendInviteButton',
      headerName: '',
      minWidth: 180,
      maxWidth: 180,
      renderCell: (val) => <ReinviteCell rowData={val} />,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',
      headerClassName: 'table-heading--empty',
    },
    {
      field: 'editButton',
      headerName: '',
      renderCell: (val) => <EditCell rowData={val} />,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',
      headerClassName: 'table-heading--empty',
    },
  ];

  // don't allow brokers to access this page
  if (isAuthStateLoaded && !admin) return <Navigate to="/" />;

  return (
    <Wrapper>
      <Typography component="h1" variant="h1">
        Staff
      </Typography>
      {!isAuthStateLoaded ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <PageActions
            rightActions={
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleAddOpen}
              >
                Add Staff
              </Button>
            }
          />

          <DataGrid
            rows={rows}
            columns={cols}
            pagination
            autoHeight
            disableRowSelectionOnClick
            disableColumnMenu
            rowHeight={60}
          />

          <CreateStaffModal isOpen={addOpen} setIsOpen={setAddOpen} />
        </>
      )}
    </Wrapper>
  );
}

export default StaffList;
