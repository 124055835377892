import * as Yup from 'yup';

const AdminSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(`Please enter the Admin's first name`)
    .min(2, 'Please enter at least 2 characters'),
  lastName: Yup.string()
    .required(`Please enter the Admin's last name`)
    .min(2, 'Please enter at least 2 characters'),
  phone: Yup.string()
    .required(`Please enter the Admin's phone number`)
    .matches(/\d{10}/g, 'Please provide a valid phone number'),
  email: Yup.string()
    .email()
    .required(`Please enter the Admin's email address`),
  tenantId: Yup.string().required(`Please select the Admin's company`),
});

export default AdminSchema;
