import { DateTime } from 'luxon';

export const getLocalDateFromISO = (d) => {
  if (d !== '') {
    const date = d.replace(/ /, 'T');
    const UTCTime = DateTime.fromISO(date);
    const localizedDate = DateTime.local();
    const localTime = UTCTime.setZone(localizedDate.zoneName);
    return localTime.toLocaleString(DateTime.DATE_SHORT);
  }
  return ' ';
};

export const getLocalTimeFromISO = (d) => {
  if (d !== '') {
    const date = d.replace(/ /, 'T');
    const UTCTime = DateTime.fromISO(date);
    const localizedDate = DateTime.local();
    const localTime = UTCTime.setZone(localizedDate.zoneName);
    return localTime.toLocaleString(DateTime.TIME_SIMPLE);
  }
  return ' ';
};

export const convertLocalDateTimeToISO = (d) => {
  const UTCTime = d.setZone('utc');
  return UTCTime.toISO();
};
