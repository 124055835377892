import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { doc, collection } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { useStorage, useFirestore } from 'reactfire';

const initialState = {
  files: [],
  setFiles: () => {},
  fileList: [],
  setFileList: null,
  deleteFiles: (id, fList, tenantId) => {},
  deleteFile: (fName) => {},
  isDeleting: false,
  isDeletingOne: false,
  docRef: { id: '' },
  tenantId: null,
  isLoading: false,
  isLoadingFiles: false,
  isSaveable: false,
  setIsSaveable: null,
  changeLog: [],
  setChangeLog: (entry) => {},
};

const SubmissionContext = createContext(initialState);

export function SubmissionMutateProvider({ children }) {
  const db = useFirestore();
  const storage = useStorage();

  const [files, setFiles] = useState(initialState.files);
  const [isDeleting, setIsDeleting] = useState(initialState.isDeleting);
  const [isDeletingOne, setIsDeletingOne] = useState(
    initialState.isDeletingOne
  );
  const [changeLog, setChangeLog] = useState([]);

  const newDocId = useMemo(
    () => doc(collection(db, 'submissions')),
    // we only want to run this once, so we deliberately add no deps to avoid the id being regenerated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteFile = useCallback(
    async (fileName) => {
      setIsDeletingOne(true);
      try {
        const storageRef = ref(storage, fileName);
        await deleteObject(storageRef);
        return true;
      } catch (error) {
        return false;
      } finally {
        setIsDeletingOne(false);
      }
    },
    [storage]
  );

  const deleteFiles = useCallback(
    async (id, fList, tenantId) => {
      setIsDeleting(true);
      if (id && fList) {
        await Promise.all(
          fList.map(async (it) => {
            await deleteFile(it.id);
          })
        );
      } else {
        await Promise.all(
          files.map(async (it) => {
            const { name } = it;
            const fileName = `${tenantId}/${newDocId.id}/${name}`;
            await deleteFile(fileName);
          })
        );
      }
      setIsDeleting(false);
    },
    [deleteFile, newDocId, files]
  );

  const getValue = useMemo(
    () => ({
      files,
      setFiles,
      deleteFiles,
      deleteFile,
      isDeleting,
      isDeletingOne,
      docRef: newDocId,
      changeLog,
      setChangeLog,
    }),
    [
      files,
      setFiles,
      deleteFiles,
      deleteFile,
      isDeleting,
      isDeletingOne,
      newDocId,
      changeLog,
      setChangeLog,
    ]
  );

  return (
    <SubmissionContext.Provider value={getValue}>
      {children}
    </SubmissionContext.Provider>
  );
}

export const useSubmissionMutateContext = () => useContext(SubmissionContext);
