import React, { FC, useMemo, useState, useCallback } from 'react';
import { saveAs } from 'file-saver';
import { doc, collection, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import {
  useConfirmDialogContext,
  ConfirmDialogProvider,
} from '../../context/DialogContext';
import { useSubmissionFilesContext } from '../../context/SubmissionFilesContext';
import { useSubmissionMutateContext } from '../../context/SubmissionMutateContext';
import { FormMode } from '../../shared/enums';
import { DateTime } from 'luxon';
import { BlobWriter, HttpReader, ZipWriter } from '@zip.js/zip.js';

type UpdateModeFileRowProps = {
  rowData: any;
};

const FileRow: FC<UpdateModeFileRowProps> = ({ rowData }) => {
  const db = useFirestore();
  const { row } = rowData;
  const { setIsOpen } = useConfirmDialogContext();
  const { changeLog, setChangeLog } = useSubmissionMutateContext();
  const onArchiveClick = useCallback(() => setIsOpen(true), [setIsOpen]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Button
        variant="text"
        color="error"
        onClick={onArchiveClick}
        disableElevation
      >
        Archive
      </Button>
      <ConfirmDialog
        title="Are you sure?"
        message={`This will archive file ${row.name}.`}
        onConfirm={async () => {
          setIsLoading(true);
          try {
            const docRef = doc(collection(db, 'files'), row.id);
            setChangeLog([...changeLog, `Archived file ${row.name}`]);
            await updateDoc(docRef, {
              isArchived: true,
              updatedAt: DateTime.local().setZone('utc').toISO(),
            });
          } catch (e) {
            // console.error(e);
          }
          setIsLoading(false);
          setIsOpen(false);
        }}
        isActing={isLoading}
      />
    </>
  );
};
type SubmissionFilesProps = {
  mode?: FormMode;
  record: Submission;
};

/**
 * Gets a unique filename if there is already a file with the same name
 * @param originalName the filename originally assigned
 * @param fileNames the stored list of filenames that have been seen
 * @returns a numbered file if it is a duplicate named file otherwise the base name
 */
function getUniqueFileName(
  originalName: string,
  fileNames: { [key: string]: number }
) {
  if (fileNames[originalName] === undefined) {
    fileNames[originalName] = 0;
    return originalName;
  }
  fileNames[originalName] += 1;
  const extensionIndex = originalName.lastIndexOf('.');
  const baseName =
    extensionIndex !== -1
      ? originalName.substring(0, extensionIndex)
      : originalName;
  const extension =
    extensionIndex !== -1 ? originalName.substring(extensionIndex) : '';
  return `${baseName} (${fileNames[originalName]})${extension}`;
}

const SubmissionFiles: FC<SubmissionFilesProps> = ({ mode, record }) => {
  const { files, isLoading, getDownloadURL } = useSubmissionFilesContext();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });
  const [isDownlodingFiles, setIsDownloadingFiles] = useState(false);

  async function getZipFileBlob() {
    const zipWriter = new ZipWriter(new BlobWriter('application/zip'));
    const fileNames: { [key: string]: number } = {};
    // Step 1: Modify filenames to ensure they are unique
    const modifiedFiles = files.map((file) => {
      const uniqueName = getUniqueFileName(file.name, fileNames);
      return { ...file, uniqueName };
    });
    // Step 2: Download files and add to zip
    await Promise.all(
      modifiedFiles
        .map(async ({ id, uniqueName }) => {
          if (!id) {
            return undefined;
          }
          const fileUrl = await getDownloadURL(id);
          if (!fileUrl) {
            return undefined;
          }
          return zipWriter.add(uniqueName, new HttpReader(fileUrl));
        })
        .filter((result) => result !== undefined)
    );
    return zipWriter.close();
  }

  const downloadFile = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    // Create a temporary anchor element and trigger a download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${record.borrowerFirstName} ${record.borrowerLastName} - Submission Files.zip`;
    document.body.appendChild(a); // Append to the document
    a.click(); // Programmatically click the element to trigger the download

    // Clean up by revoking the Blob URL and removing the anchor element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleDownloadAllFiles = async () => {
    setIsDownloadingFiles(true);
    try {
      const zipFile = await getZipFileBlob();
      downloadFile(zipFile);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloadingFiles(false);
    }
  };

  const columns: GridColDef<SubmissionFile>[] = useMemo(() => {
    const cols: GridColDef<SubmissionFile>[] = [
      {
        field: 'name',
        headerName: '',
        flex: 1,
        editable: false,
        headerClassName: 'table-heading--empty',
      },
    ];
    if (mode !== FormMode.UPDATE) {
      cols.push({
        field: 'downloadButton',
        headerName: '',
        width: 155,
        renderCell: (params: GridRenderCellParams<SubmissionFile>) => (
          <Button
            variant="text"
            color="secondary"
            disableElevation
            onClick={async () => {
              const id = params?.row?.id;
              if (id) {
                const dUrl = await getDownloadURL(id);
                if (dUrl) {
                  saveAs(dUrl, params.row.name);
                }
              }
            }}
          >
            Download
          </Button>
        ),
        // disableClickEventBubbling: true,
        align: 'center',
        headerClassName: 'table-heading--empty',
      });
    }
    if (mode === FormMode.UPDATE) {
      cols.push({
        field: 'archiveButton',
        headerName: '',
        width: 155,
        renderCell: (params: GridRenderCellParams<SubmissionFile>) => (
          <ConfirmDialogProvider>
            <FileRow rowData={params} />
          </ConfirmDialogProvider>
        ),
        // disableClickEventBubbling: true,
        align: 'center',
        headerClassName: 'table-heading--empty',
      });
    }
    return cols;
  }, [getDownloadURL, mode]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Typography component="h2" variant="h2">
          Files
        </Typography>
        <Button
          variant="outlined"
          disableElevation
          color="secondary"
          onClick={handleDownloadAllFiles}
          disabled={!files || isDownlodingFiles}
        >
          DOWNLOAD ALL FILES
        </Button>
      </Box>
      <DataGrid
        rows={files}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[12, 24, 36]}
        loading={isLoading}
        rowHeight={60}
        className="hide-headers"
        pagination
        autoHeight
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default SubmissionFiles;
