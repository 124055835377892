import React, { FC, useState } from 'react';
import { collection, setDoc, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormikTextField from '../FormikTextField';
import Loading from '../Loading';

const CompanyForm = () => {
  const { handleChange, values } = useFormikContext<Tenant>();
  return (
    <Form id="addCompanyForm">
      <Box className="grid--form--modal">
        <FormikTextField
          name="name"
          id="name"
          label="Company Name"
          onChange={handleChange}
          value={values.name}
        />
      </Box>
    </Form>
  );
};

type CompanyModalProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  checkData: string[];
};

const CreateCompanyModal: FC<CompanyModalProps> = ({
  setIsOpen,
  isOpen,
  checkData,
}) => {
  const db = useFirestore();

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (values: Tenant) => {
    setIsLoading(true);
    try {
      const docRef = doc(collection(db, 'tenants'));
      await setDoc(docRef, {
        name: values.name.trim(),
      });
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const companySchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a company name')
      .min(2, 'Please enter at least 2 characters')
      .test({
        name: 'companyExists',
        exclusive: true,
        message: 'A company with that name already exists',
        test: (value) => {
          const res = value
            ? !checkData.includes(value?.toLowerCase().trim())
            : true;
          return res;
        },
      }),
  });

  const initialValues: Tenant = {
    name: '',
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" scroll="paper" fullWidth>
      <DialogTitle color="status.primary">Add a Company</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={companySchema}
          enableReinitialize
        >
          <>
            {isLoading && <Loading />}
            {!isLoading && <CompanyForm />}
          </>
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          form="addCompanyForm"
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCompanyModal;
