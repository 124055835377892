import React, { FC, useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';

export interface UploadInputProps {
  uploadInputId: string;
  uploadInputTitle: string;
  dropZoneOptions?: DropzoneOptions;
  files: File[];
  setFiles: (files: File[]) => void;
}

const UploadInput: FC<UploadInputProps> = ({
  uploadInputId,
  uploadInputTitle,
  dropZoneOptions,
  files,
  setFiles,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropZoneOptions,
  });

  const clearFile = useCallback(
    (name: string) => {
      const newFileList = files.filter((it) => it.name !== name);
      setFiles(newFileList);
    },
    [files, setFiles]
  );

  return (
    <Box
      {...getRootProps()}
      onClick={(e: any) => e.stopPropagation()}
      className="section"
    >
      <Box className="dropzone">
        <input
          hidden
          id={`uploadInput-${uploadInputId}`}
          type="file"
          {...getInputProps()}
        />
        <InputLabel htmlFor={`uploadInput-${uploadInputId}`}>
          <Box className="dropzone--drop-area">
            <Typography
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign="center"
            >
              {uploadInputTitle}
            </Typography>
          </Box>
        </InputLabel>
        {!!files.length && (
          <>
            <Box className="dropzone--file-list">
              <ul>
                {files.map((it) => (
                  <li key={it.name}>
                    {it.name}{' '}
                    <Button color="error" onClick={() => clearFile(it.name)}>
                      Remove
                    </Button>
                  </li>
                ))}
                <li className="dropzone--remove-all">
                  <Button
                    color="error"
                    onClick={() => setFiles([])}
                    disabled={!files.length}
                  >
                    Remove All
                  </Button>
                </li>
              </ul>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UploadInput;
