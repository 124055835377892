export type ValueWithLabel = {
  label: string;
  value: string;
};

export type SubmissionStatus = {
  INITIAL_SUBMISSION: ValueWithLabel;
  IN_PROCESS: ValueWithLabel;
  REQUIRES_REVIEW: ValueWithLabel;
  COMPLETE: ValueWithLabel;
  INCOMPLETE: ValueWithLabel;
};

export type LoanProgram = {
  MONTH_12: ValueWithLabel;
  MONTH_24: ValueWithLabel;
};

export type BankStatementProgram = {
  MONTH_12: ValueWithLabel;
  MONTH_24: ValueWithLabel;
};

export type BankStatementType = {
  BUSINESS: ValueWithLabel;
  PERSONAL: ValueWithLabel;
};

export type BusinessExpenseFactor = {
  FIXEDEXPENSE: ValueWithLabel;
  FIFTYPERCENT: ValueWithLabel;
  TAXORCPA: ValueWithLabel;
  THIRDPARTYPL: ValueWithLabel;
  ZEROPERCENT: ValueWithLabel;
  TENPERCENT: ValueWithLabel;
};

export const SubmissionStatuses: SubmissionStatus = {
  INITIAL_SUBMISSION: {
    value: 'INITIAL_SUBMISSION',
    label: 'Initial Submission',
  },
  IN_PROCESS: {
    value: 'IN_PROCESS',
    label: 'In Process',
  },
  REQUIRES_REVIEW: {
    value: 'REQUIRES_REVIEW',
    label: 'Requires Review',
  },
  COMPLETE: {
    value: 'COMPLETE',
    label: 'Complete',
  },
  INCOMPLETE: {
    value: 'INCOMPLETE',
    label: 'Incomplete',
  },
};

export const LoanProgramOptions: LoanProgram = {
  MONTH_12: {
    value: 'MONTH_12',
    label: '12 Month bank statement',
  },
  MONTH_24: {
    value: 'MONTH_24',
    label: '24 Month bank statement',
  },
};

export const BankStatementProgramOptions: BankStatementProgram = {
  MONTH_12: {
    value: 'MONTH_12',
    label: '12 Months',
  },
  MONTH_24: {
    value: 'MONTH_24',
    label: '24 Months',
  },
};

export const BankStatementTypeOptions: BankStatementType = {
  BUSINESS: {
    value: 'BUSINESS',
    label: 'Business Bank Statements',
  },
  PERSONAL: {
    value: 'PERSONAL',
    label: 'Personal Bank Statements',
  },
};

export const BusinessExpenseFactorOptions: BusinessExpenseFactor = {
  FIXEDEXPENSE: {
    value: 'FIXEDEXPENSE',
    label: '50% Fixed Expense',
  },
  FIFTYPERCENT: {
    value: 'FIFTYPERCENT',
    label: '50% Fixed Expense',
  },
  TAXORCPA: {
    value: 'TAXORCPA',
    label: 'Tax Preparer/CPA Certification',
  },
  THIRDPARTYPL: {
    value: 'THIRDPARTYPL',
    label: 'Third Party Profit & Loss',
  },
  TENPERCENT: {
    value: 'TENPERCENT',
    label: '10%',
  },
  ZEROPERCENT: {
    value: 'ZEROPERCENT',
    label: '0%',
  },
};

export const RESET_EMAIL_MESSAGE =
  'Get instructions sent to this email that explain how to reset your password';
