import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Nav from './Nav';

function Wrapper({ children }) {
  return (
    <Box className="wrapper--main">
      <Nav />
      <Container className="container--content">{children}</Container>
    </Box>
  );
}

export default Wrapper;
