import React, { FC, useMemo } from 'react';
import { doc, collection, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import PageActions from './PageActions';
import { useSubmissionMutateContext } from '../../context/SubmissionMutateContext';
// import SubmitModal from '../Modal/SubmitModal';
// import { SubmissionStatuses } from '../../shared/constants';

type SubmissionUpdateActionsProps = {
  isLoading: boolean;
  // record: Submission;
};

const SubmissionUpdateActions: FC<SubmissionUpdateActionsProps> = ({
  isLoading,
  // record,
}) => {
  const { files } = useSubmissionMutateContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const db = useFirestore();
  const { submitForm, dirty } = useFormikContext<Submission>();
  // const formRecord = useFormikContext<Submission>();
  // const [isSubmitOpen, setIsSubmitOpen] = useState(false);

  const isDisabled = useMemo(() => {
    if (isLoading) return true;
    if (!dirty && files.length) return false;
    if (dirty) return false;
    return true;
  }, [isLoading, dirty, files]);

  return (
    <PageActions
      rightActions={
        <>
          <Button
            type="button"
            disabled={isLoading}
            variant="text"
            color="secondary"
            onClick={async (e) => {
              e.preventDefault();
              const docRef = doc(collection(db, 'submissions'), id);
              await updateDoc(docRef, {
                isLocked: false,
                lockDateTime: null,
                lockedBy: null,
              });
              navigate(`/submission/${id}`);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={isDisabled}
            onClick={submitForm}
          >
            Save
          </Button>
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={isDisabled}
            onClick={() => setIsSubmitOpen(true)}
          >
            {record.status === SubmissionStatuses.INITIAL_SUBMISSION.value
              ? 'Save and send to analyzer'
              : 'Save and resend to analyzer'}
          </Button>
          <SubmitModal
            isOpen={isSubmitOpen}
            setIsOpen={setIsSubmitOpen}
            record={formRecord.values}
            submit
            id={formRecord.values.id}
            submitForm={submitForm}
          /> */}
        </>
      }
    />
  );
};

export default SubmissionUpdateActions;
