import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  gridNumberComparator,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CustomToolbar from './CustomToolbar';
import {
  getLocalDateFromISO,
  convertLocalDateTimeToISO,
} from '../../utils/dateUtils';
import {
  LoanProgram,
  LoanProgramOptions,
  SubmissionStatus,
  SubmissionStatuses,
} from '../../shared/constants';
import { useAuthContext } from '../../context/AuthContext';
import { useBrokerContext } from '../../context/BrokerContext';
import { useSubmissionsFilterContext } from '../../context/SubmissionsFilterContext';
import PageActions from '../PageElements/PageActions';

const EditRow = ({ rowData }: { rowData: any }) => {
  const db = useFirestore();
  const navigate = useNavigate();
  const { row } = rowData;
  const { id, isLocked, lockedBy, lockDateTime } = row;
  const {
    userData,
    claims: { broker: isBroker },
  } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = useCallback(async () => {
    const url = `/submission/${id}/update/`;
    const docRef = doc(collection(db, 'submissions'), id);
    const nowDT = DateTime.local();
    if (isLocked) {
      if (lockedBy === userData?.displayName) {
        navigate(url);
      } else {
        const lockDT = DateTime.fromISO(lockDateTime).setZone(
          DateTime.local().zoneName
        );
        const diff = lockDT.diff(nowDT, ['minutes']);
        if (diff.minutes > 12) {
          await updateDoc(docRef, {
            lockDateTime: convertLocalDateTimeToISO(nowDT),
            isLocked: true,
            lockedBy: userData?.displayName,
          });
          navigate(url);
        } else {
          setIsOpen(true);
        }
      }
    } else {
      await updateDoc(docRef, {
        lockDateTime: convertLocalDateTimeToISO(nowDT),
        isLocked: true,
        lockedBy: userData?.displayName,
      });
      navigate(url);
    }
  }, [setIsOpen, db, id, isLocked, lockDateTime, navigate, userData, lockedBy]);

  return (
    <>
      {(!isBroker ||
        (row.status !== SubmissionStatuses.REQUIRES_REVIEW.value &&
          row.status !== SubmissionStatuses.COMPLETE.value &&
          row.status !== SubmissionStatuses.IN_PROCESS.value)) && (
        <Button
          variant="text"
          color="secondary"
          disableElevation
          onClick={() => handleEditClick()}
        >
          Edit
        </Button>
      )}
      <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
        <DialogTitle color="status.primary">Submission Locked</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This submission is currently being edited by {lockedBy}.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '10px 24px 20px 24px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function SubmissionList() {
  const db = useFirestore();
  const {
    claims: { broker: isBroker, admin: isAdmin, staff: isStaff, tenantId },
  } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Submission[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { isArchived } = useSubmissionsFilterContext();

  const condition = useMemo(
    () => (isBroker ? where('tenantId', '==', tenantId) : null),
    [isBroker, tenantId]
  );

  const constraints = useMemo(() => {
    const def = [orderBy('createdAt', 'desc')];
    if (condition) {
      def.push(condition);
    }
    return def;
  }, [condition]);

  const q = useMemo(
    () => query(collection(db, 'submissions'), ...constraints),
    [constraints, db]
  );

  useEffect(() => {
    setIsLoading(true);
    const unsub = onSnapshot(q, (snap) => {
      const docs: any[] = [];
      snap.forEach((it) =>
        docs.push({
          id: it.id,
          ...it.data(),
        })
      );
      setData(docs);
      setIsLoading(false);
    });
    return unsub;
  }, [q]);

  const { brokers, isLoading: isBrokersLoading } = useBrokerContext();

  const rows = useMemo(
    () =>
      isLoading
        ? []
        : data
            .map((d) => {
              // This whole bit is temporary since the existing docs don't have a value for "isArchived"
              const { isArchived: isDocArchived, ...rest } = d;
              return {
                ...rest,
                isArchived: isDocArchived === undefined ? false : isDocArchived,
              };
            })
            .filter((it) => it.isArchived === isArchived),
    [data, isArchived, isLoading]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.8,
        editable: false,
        renderCell: (val: GridRenderCellParams) => {
          const status = val?.row?.status as keyof SubmissionStatus;
          return `${
            SubmissionStatuses[status]?.value ===
              SubmissionStatuses.REQUIRES_REVIEW.value && isBroker
              ? SubmissionStatuses.IN_PROCESS.label
              : SubmissionStatuses[status]?.label
          }`;
        },
      },
      {
        field: 'brokerName',
        headerName: 'Originator',
        flex: 1,
        editable: false,
        valueGetter: (params: GridRenderCellParams<Submission>) => {
          const { brokerId } = params.row;
          const brokerName = isBrokersLoading
            ? undefined
            : brokers.find((it) => it.id === brokerId);
          return {
            brokerFirstName: brokerName?.firstName,
            brokerLastName: brokerName?.lastName,
            foundBroker: !!brokerName,
          };
        },
        valueFormatter: (params) => {
          const { value } = params;
          if (value.foundBroker) {
            return `${value.brokerLastName}, ${value.brokerFirstName}`;
          }
          return 'N/A';
        },
        sortComparator: (v1, v2, param1, param2) => {
          // Sort N/A to the bottom
          const foundBrokerCompareResult = gridNumberComparator(
            v2.foundBroker,
            v1.foundBroker,
            param1,
            param2
          );

          if (foundBrokerCompareResult !== 0) {
            return foundBrokerCompareResult;
          }

          const lastNameCompareResult = gridStringOrNumberComparator(
            v1.brokerLastName,
            v2.brokerLastName,
            param1,
            param2
          );

          if (lastNameCompareResult !== 0) {
            return lastNameCompareResult;
          }

          return gridStringOrNumberComparator(
            v1.brokerFirstName,
            v2.brokerFirstName,
            param1,
            param2
          );
        },
        renderCell: (val) => {
          const b = isBrokersLoading
            ? ''
            : brokers.find((it) => it.id === val.row.brokerId);
          return b ? `${b.lastName}, ${b.firstName}` : 'N/A';
        },
      },
      {
        field: 'borrowerName',
        headerName: 'Borrower',
        flex: 1,
        editable: false,
        valueGetter: (params) => {
          return `${params.row.borrowerLastName}, ${params.row.borrowerFirstName}`;
        },
        sortComparator: (v1, v2, param1, param2) => {
          const [v1BorrowerLastName, v1BorrowerFirstName] = v1.split(',');
          const [v2BorrowerLastName, v2BorrowerFirstName] = v2.split(',');
          const lastNameCompareResult = gridStringOrNumberComparator(
            v1BorrowerLastName,
            v2BorrowerLastName,
            param1,
            param2
          );

          if (lastNameCompareResult !== 0) {
            return lastNameCompareResult;
          }

          return gridStringOrNumberComparator(
            v1BorrowerFirstName,
            v2BorrowerFirstName,
            param1,
            param2
          );
        },
      },
      {
        field: 'loanProgram',
        headerName: 'Loan Program',
        flex: 1.2,
        editable: false,
        renderCell: (val: GridCellParams) =>
          `${
            LoanProgramOptions[val.row.loanProgram as keyof LoanProgram]?.label
          }`,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 0.6,
        editable: false,
        renderCell: (val) => getLocalDateFromISO(val.row.createdAt),
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        flex: 0.6,
        editable: false,
        renderCell: (val) => getLocalDateFromISO(val.row.updatedAt),
        headerClassName: 'table-heading--empty',
      },
      {
        field: 'viewButton',
        headerName: '',
        renderCell: (d) => (
          <Button
            variant="text"
            color="secondary"
            disableElevation
            component={Link}
            to={`/submission/${d.row.id}/`}
          >
            View
          </Button>
        ),
        sortable: false,
        align: 'center',
        headerClassName: 'table-heading--empty',
      },
      {
        field: 'editButton',
        headerName: '',
        renderCell: (params) => <EditRow rowData={params} />,
        sortable: false,
        align: 'center',
        headerClassName: 'table-heading--empty',
      },
    ],
    [isBroker, brokers, isBrokersLoading]
  );

  return (
    <>
      {(isBroker || isAdmin || isStaff) && (
        <PageActions
          rightActions={
            <Button
              variant="contained"
              component={Link}
              to="/submission/create/"
              disableElevation
              color="primary"
            >
              Add new
            </Button>
          }
        />
      )}
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              brokerName: !isBroker,
            },
          },
          sorting: {
            sortModel: [{ field: 'status', sort: 'asc' }],
          },
        }}
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 50]}
        loading={isLoading}
        pagination
        autoHeight
        slots={{ toolbar: CustomToolbar }}
        disableRowSelectionOnClick
        rowHeight={60}
      />
    </>
  );
}

export default SubmissionList;
