import React, { createContext, useContext, useMemo } from 'react';
import { collection, where, query, DocumentData } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useAuthContext } from './AuthContext';

type BrokerValue = {
  brokers: DocumentData[];
  isLoading: boolean;
};

const initialState: BrokerValue = {
  brokers: [],
  isLoading: false,
};

const BrokerContext = createContext(initialState);

type BrokerProviderProps = {
  children: React.ReactNode;
};

export function BrokerProvider(props: BrokerProviderProps) {
  const { children } = props;

  const db = useFirestore();
  const {
    claims: { broker: isBroker, tenantId },
  } = useAuthContext();

  const constraints = useMemo(() => {
    const res = [
      where('type', '==', 'Broker'),
      where('isDeleted', '==', false),
    ];
    if (isBroker) {
      res.push(where('tenantId', '==', tenantId));
    }
    return res;
  }, [isBroker, tenantId]);

  const qBrokers = query(collection(db, 'users'), ...constraints);

  const { status, data } = useFirestoreCollectionData(qBrokers, {
    idField: 'id',
  });

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: BrokerValue = useMemo(
    () => ({
      brokers: data,
      isLoading,
    }),
    [data, isLoading]
  );

  return (
    <BrokerContext.Provider value={getValue}>{children}</BrokerContext.Provider>
  );
}

export const useBrokerContext = () => useContext(BrokerContext);
