import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import Select from '@mui/material/Select';
import { useStaffContext } from '../../context/StaffContext';
import { useAdminContext } from '../../context/AdminContext';

function StaffSelect(props: SelectProps) {
  const {
    id,
    name,
    label,
    required,
    disabled,
    value,
    onChange,
    error,
    helperText,
  } = props;
  const { staff } = useStaffContext();
  const { admin } = useAdminContext();
  const staffAdmin = staff.concat(admin);
  return (
    <FormControl
      margin="dense"
      size="small"
      color="inputs"
      required={required}
      disabled={disabled}
      error={error}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        name={name}
        labelId={`${id}-label`}
        id={id}
        label="Account Executive"
        onChange={(e) => onChange(e)}
        value={value}
      >
        {staffAdmin?.map((b) => (
          <MenuItem key={b.id} value={b.id}>
            {b.lastName}, {b.firstName}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default StaffSelect;
