// eslint-disable-next-line import/no-mutable-exports
const firebaseConfig = {
  apiKey: 'AIzaSyAdLrEFwRTr2dsyxVcIj7XoAnINLYsLO98',
  authDomain: 'emporium-broker-portal-prod.firebaseapp.com',
  projectId: 'emporium-broker-portal-prod',
  storageBucket: 'emporium-broker-portal-prod.appspot.com',
  messagingSenderId: '255506619559',
  appId: '1:255506619559:web:dbd0287c76a0508a23cf82',
  measurementId: 'G-3WELP8V65R',
};

export default firebaseConfig;
