import React, { FC } from 'react';
import { DateTime } from 'luxon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Loading from '../Loading';
import { useAuditLogContext } from '../../context/AuditLogContext';

type AuditLogModalProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};

const AuditLogModal: FC<AuditLogModalProps> = ({ setIsOpen, isOpen }) => {
  const { isLoading, logs } = useAuditLogContext();
  const sorted =
    logs && logs.length
      ? logs
          .map((it) => {
            const { dateTime } = it;
            const dtObj = DateTime.fromISO(dateTime).setZone(
              DateTime.local().zoneName
            );
            return {
              ...it,
              dtObj,
            };
          })
          .sort((a, b) => {
            const { dtObj: adt } = a;
            const { dtObj: bdt } = b;
            if (adt < bdt) return 1;
            if (bdt < adt) return -1;
            return 0;
          })
      : [];

  const content =
    sorted && sorted.length ? (
      sorted.map((it) => {
        const { dtObj, userName, actionDetails, id } = it;
        return (
          <Paper
            variant="outlined"
            sx={{
              padding: '8px',
              marginBottom: '12px',
              backgroundColor: 'rgba(19, 52, 99, .05)',
            }}
            key={id}
          >
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={actionDetails.reduce(
                  (a: React.ReactNode | null, nx) => (
                    <>
                      {!!a && (
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ display: 'block' }}
                        >
                          {a}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ display: 'block' }}
                      >
                        {nx}
                      </Typography>
                    </>
                  ),
                  null
                )}
                secondary={
                  <Typography variant="caption">
                    By: {userName} on{' '}
                    {dtObj.toLocaleString(DateTime.DATE_SHORT)} at{' '}
                    {dtObj.toLocaleString(DateTime.TIME_SIMPLE)}
                  </Typography>
                }
              />
            </ListItem>
          </Paper>
        );
      })
    ) : (
      <Typography variant="h4" component="h4">
        No logs available for this submission
      </Typography>
    );

  return (
    <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
      <DialogTitle color="status.primary">Audit Log</DialogTitle>
      <DialogContent>
        <>
          {isLoading && <Loading />}
          {!isLoading && <List>{content}</List>}
        </>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => setIsOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditLogModal;
