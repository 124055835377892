import React, { FC, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { httpsCallableFromURL } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import parsePhoneNumber from 'libphonenumber-js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import FormikTextField from '../FormikTextField';
import Loading from '../Loading';
import BrokerSchema from '../../schema/Broker';

const { REACT_APP_EDIT_USER_FN_NAME } = process.env;

type BrokerFormProps = {
  error?: string;
};

const BrokerForm: FC<BrokerFormProps> = ({ error }) => {
  const { handleChange, values } = useFormikContext<User>();
  return (
    <Form id={`updateBrokerForm-${values.id}`}>
      <Box className="grid--form--modal">
        {!!error && (
          <Typography component="h4" variant="h4" color="error">
            {error}
          </Typography>
        )}
        <FormikTextField
          name="firstName"
          id="firstName"
          label="First Name"
          onChange={handleChange}
          value={values.firstName}
        />
        <FormikTextField
          name="lastName"
          id="lastName"
          label="Last Name"
          onChange={handleChange}
          value={values.lastName}
        />
        <FormikTextField
          type="tel"
          name="phone"
          id="phone"
          label="Phone"
          onChange={handleChange}
          value={values.phone}
        />
        <FormikTextField
          type="email"
          name="email"
          id="email"
          label="Email"
          onChange={handleChange}
          value={values.email}
        />
      </Box>
    </Form>
  );
};

type BrokerModalProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  record: User;
};

type FnCallableResult = {
  data: any;
};

const UpdateBrokerModal: FC<BrokerModalProps> = ({
  setIsOpen,
  isOpen,
  record,
}) => {
  const fns = useFunctions();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const updateBrokerFn = httpsCallableFromURL(
    fns,
    REACT_APP_EDIT_USER_FN_NAME as string
  );

  const { phone, ...r } = record;

  const parsedNumber = parsePhoneNumber(phone as string, 'US');

  const initialValues: User = {
    ...r,
    phone: parsedNumber
      ? parsedNumber.formatNational().replace(/[\s()-]/gi, '')
      : '',
  };

  const submitHandler = async (values: User) => {
    setIsLoading(true);
    try {
      const { tenantId, type, isDeleted, ...rest } = values;
      const result = await updateBrokerFn({ ...rest });
      if (result && (result as unknown as FnCallableResult)?.data?.error) {
        const msg = (result as unknown as FnCallableResult)?.data?.error
          ?.errorInfo?.message;
        setError(msg);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
      <DialogTitle color="status.primary">Update Broker</DialogTitle>
      <DialogContent>
        <Formik
          key={record.id}
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={BrokerSchema}
          enableReinitialize
        >
          <>
            {isLoading && <Loading />}
            {!isLoading && <BrokerForm error={error} />}
          </>
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          form={`updateBrokerForm-${record.id}`}
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBrokerModal;
