import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';
import { formatError } from '../../../utils/format';

export default function Auth() {
  const [forgotPassoword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const auth = getAuth();

  const handleClickSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoginError(false);
      setLoginErrorMessage('');
    } catch (e) {
      setLoginErrorMessage(formatError(e.code));
      setLoginError(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (email.length === 0 || password.length === 0) {
      setLoginError(false);
    }
  }, [email.length, password.length]);

  return forgotPassoword ? (
    <ResetPassword
      setForgotPassword={setForgotPassword}
      email={email}
      auth={auth}
    />
  ) : (
    <LoginForm
      setForgotPassword={setForgotPassword}
      password={password}
      setPassword={setPassword}
      email={email}
      setEmail={setEmail}
      handleClickSignIn={handleClickSignIn}
      showPassword={showPassword}
      handleClickShowPassword={handleClickShowPassword}
      loginError={loginError}
      setLoginError={setLoginError}
      loginErrorMessage={loginErrorMessage}
    />
  );
}
