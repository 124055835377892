import React, { createContext, useContext, useMemo } from 'react';
import { collection, orderBy, query, DocumentData } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

type TenantValue = {
  tenants: DocumentData[];
  isLoading: boolean;
};

const initialState: TenantValue = {
  tenants: [],
  isLoading: false,
};

const TenantContext = createContext(initialState);

type TenantProviderProps = {
  children: React.ReactNode;
};

export function TenantProvider(props: TenantProviderProps) {
  const { children } = props;

  const db = useFirestore();

  const qTenants = query(collection(db, 'tenants'), orderBy('name', 'desc'));

  const { status, data } = useFirestoreCollectionData(qTenants, {
    idField: 'id',
  });

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: TenantValue = useMemo(
    () => ({
      tenants: data,
      isLoading,
    }),
    [data, isLoading]
  );

  return (
    <TenantContext.Provider value={getValue}>{children}</TenantContext.Provider>
  );
}

export const useTenantContext = () => useContext(TenantContext);
