import React from 'react';
import {
  Stack,
  TextField,
  Typography,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const loginTextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#133463',
      color: '#133463',
    },
  },
  '& label.Mui-focused': {
    color: '#133463',
  },
};

export default function LoginForm({
  handleClickSignIn,
  setForgotPassword,
  setEmail,
  setPassword,
  email,
  password,
  showPassword,
  handleClickShowPassword,
  loginError,
  loginErrorMessage,
}) {
  return (
    <Stack spacing="24px">
      <TextField
        size="small"
        label="Email"
        sx={loginTextFieldStyle}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="off"
        value={email || ''}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        size="small"
        label="Password"
        value={password || ''}
        helperText={loginError ? loginErrorMessage : ''}
        type={showPassword ? 'text' : 'password'}
        sx={{
          ...loginTextFieldStyle,
          '.MuiFormHelperText-root': {
            color: 'red !important',
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                sx={{ backgroundColor: 'transparent' }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="text"
          sx={{
            color: '#4285F4',
            fontSize: '14px',
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          onClick={() => setForgotPassword(true)}
        >
          Trouble signing in?
        </Typography>
        <Button
          disableElevation
          variant="contained"
          onClick={handleClickSignIn}
        >
          Sign In
        </Button>
      </Box>
    </Stack>
  );
}
