import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function PageActions(props) {
  const { leftActions, rightActions } = props;

  return (
    <Box className="page-actions">
      {leftActions && (
        <Box
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            '& button, & a': {
              marginRight: '16px',
            },
          }}
        >
          {leftActions}
        </Box>
      )}
      {rightActions && (
        <Box
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'flex-end',
            '& button, & a': {
              marginLeft: '16px',
            },
          }}
        >
          {rightActions}
        </Box>
      )}
    </Box>
  );
}

PageActions.propTypes = {
  leftActions: PropTypes.node,
  rightActions: PropTypes.node.isRequired,
};

PageActions.defaultProps = {
  leftActions: null,
};

export default PageActions;
