import React, { FC, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useSubmissionResultsContext } from '../../context/SubmissionResultsContext';
import { getLocalDateFromISO } from '../../utils/dateUtils';
import { useAuthContext } from '../../context/AuthContext';
import { SubmissionStatuses } from '../../shared/constants';

type SubmissionResultProps = {
  record: Submission;
};

const SubmissionResults: FC<SubmissionResultProps> = ({ record }) => {
  const { files, isLoading, getDownloadURL } = useSubmissionResultsContext();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });
  const {
    claims: { broker: isBroker, staff: isStaff, admin: isAdmin },
  } = useAuthContext();

  const columns: GridColDef<SubmissionResult>[] = useMemo(() => {
    const cols: GridColDef<SubmissionResult>[] = [
      {
        field: 'dateTime',
        headerName: 'Date',
        flex: 1,
        editable: false,
        renderCell: (params: GridRenderCellParams<SubmissionResult>) =>
          getLocalDateFromISO(params.row.dateTime),
      },
      {
        field: 'fileName',
        headerName: 'Filename',
        flex: 1,
        editable: false,
      },
      {
        field: 'downloadButton',
        headerName: '',
        width: 155,
        renderCell: (params: GridRenderCellParams<SubmissionResult>) => (
          <Button
            variant="text"
            color="secondary"
            disableElevation
            onClick={async () => {
              const { id } = params.row;
              if (id) {
                const dUrl = await getDownloadURL(id);
                if (dUrl) {
                  saveAs(dUrl, params.row.fileName);
                }
              }
            }}
          >
            Download
          </Button>
        ),
        // disableClickEventBubbling: true,
        align: 'center',
        headerClassName: 'table-heading--empty',
      },
    ];
    return cols;
  }, [getDownloadURL]);

  const brokerViewEnabled =
    isBroker && record.status === SubmissionStatuses.COMPLETE.value;
  const rows = brokerViewEnabled ? files.slice(-1) : files;

  return (
    (brokerViewEnabled || isStaff || isAdmin) && (
      <Box sx={{ marginTop: '32px' }}>
        <Typography component="h2" variant="h2" sx={{ marginBottom: '10px' }}>
          Analyzer Results
        </Typography>
        <DataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[12, 24, 36]}
          loading={isLoading}
          rowHeight={60}
          className="hide-headers"
          pagination
          autoHeight
          disableRowSelectionOnClick
        />
      </Box>
    )
  );
};

export default SubmissionResults;
