import React from 'react';
import SubmissionDetails from '../../components/SubmissionDetails';
import Wrapper from '../../components/Layout/Wrapper';
import { SubmissionFilesProvider } from '../../context/SubmissionFilesContext';
import { SubmissionResultsProvider } from '../../context/SubmissionResultsContext';

function SubmissionView() {
  return (
    <Wrapper>
      <SubmissionFilesProvider>
        <SubmissionResultsProvider>
          <SubmissionDetails />
        </SubmissionResultsProvider>
      </SubmissionFilesProvider>
    </Wrapper>
  );
}

export default SubmissionView;
