import React, { createContext, useContext, useState, useMemo } from 'react';

interface SubmissionsFilterValue {
  isArchived: boolean;
  setIsArchived: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubmissionsFilterContext = createContext<SubmissionsFilterValue | null>(
  null
);

export function SubmissionsFilterProvider({ children }: { children: any }) {
  const [isArchived, setIsArchived] = useState<boolean>(false);

  const getValue: SubmissionsFilterValue = useMemo(
    () => ({
      isArchived,
      setIsArchived,
    }),
    [isArchived, setIsArchived]
  );

  return (
    <SubmissionsFilterContext.Provider value={getValue}>
      {children}
    </SubmissionsFilterContext.Provider>
  );
}

export const useSubmissionsFilterContext = () => {
  const submissionFilterContext = useContext(SubmissionsFilterContext);

  if (!submissionFilterContext) {
    throw new Error(
      'useSubmissionsFilterContext has to be used within <SubmissionsFilterContext.Provider>'
    );
  }
  return submissionFilterContext;
};
