import React, { FC } from 'react';
import { useField } from 'formik';
import Select from '../Select';
import BrokerSelect from '../Select/BrokerSelect';
import CompanySelect from '../Select/CompanySelect';
import StaffSelect from '../Select/StaffSelect';

type ControllerComponentType =
  | 'select'
  | 'brokerSelect'
  | 'companySelect'
  | 'staffSelect';

type FormikSelectProps = DefaultSelectProps & {
  name: string;
  type?: ControllerComponentType;
};

const getControllerComponent = (controllerType: ControllerComponentType) => {
  switch (controllerType) {
    case 'select':
      return Select;
    case 'brokerSelect':
      return BrokerSelect;
    case 'companySelect':
      return CompanySelect;
    case 'staffSelect':
      return StaffSelect;
    default:
      throw new Error('Type is not defined');
  }
};

const FormikSelect: FC<FormikSelectProps> = ({
  name,
  type = 'select',
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const ControllerComponent = getControllerComponent(type);

  return (
    <ControllerComponent
      {...rest}
      {...field}
      name={name}
      error={touched && Boolean(error)}
      helperText={touched ? error : ''}
    />
  );
};

export default FormikSelect;
