import parsePhoneNumber from 'libphonenumber-js';

export const formatNumber = (num?: string) => {
  const res = {
    formatted: '',
    uri: '',
  };
  if (!num) return res;
  const parsedNumber = parsePhoneNumber(num, 'US');
  return parsedNumber
    ? {
        formatted: parsedNumber.formatNational(),
        uri: parsedNumber.getURI(),
      }
    : res;
};

export const formatError = (code: string) => {
  const noAuthCode = code.split('/').pop();
  const noHyponsCode = noAuthCode?.replace(/-/g, ' ');
  const capFirstLetter = noHyponsCode?.charAt(0).toUpperCase();

  return capFirstLetter + (noHyponsCode?.slice(1) || '');
};
