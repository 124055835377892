export enum UserType {
  Admin,
  Staff,
  Broker,
}

export enum SubmissionType {
  INITIAL = 'NewLoan',
  RESUBMIT = 'UpdateLoan',
}

export enum FormMode {
  CREATE,
  UPDATE,
}
