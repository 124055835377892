import React, { useState, useMemo } from 'react';
import * as FBAuth from 'firebase/auth';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useFirestore, useFirestoreDocData, useStorage } from 'reactfire';
import { useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Wrapper from '../../components/Layout/Wrapper';
import Loading from '../../components/Loading';
import SubmissionForm from '../../components/Forms/SubmissionForm';
import SubmissionSchema from '../../schema/Submission';
import UploadInputWrapper from '../../components/UploadInputWrapper';
import SubmissionFiles from '../../components/SubmissionDetails/SubmissionFiles';
import SubmissionCountdown from '../../components/SubmissionCountdown';
import { useAuthContext } from '../../context/AuthContext';
import { SubmissionStatuses } from '../../shared/constants';
import { useSubmissionMutateContext } from '../../context/SubmissionMutateContext';
import SubmissionUpdateActions from '../../components/PageElements/SubmissionUpdateActions';
import { convertLocalDateTimeToISO } from '../../utils/dateUtils';
import { FormMode } from '../../shared/enums';

function getDiffs<T extends Record<string, unknown>>(
  obj1: T,
  obj2: T
): Partial<T> {
  const res: Partial<T> = {};
  const uniqueKeys = [...new Set(Object.keys(obj1).concat(Object.keys(obj2)))];
  return uniqueKeys.reduce((acc: Partial<T>, nx: string) => {
    const key = nx as keyof typeof obj1;
    const valA = obj1[key];
    const valB = obj2[key];
    if (valB !== valA) return { ...acc, [nx]: valB };
    return acc;
  }, res);
}

function SubmissionEdit() {
  const db = useFirestore();
  const storage = useStorage();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    isAuthStateLoaded,
    userData,
    claims: { broker: isBroker },
  } = useAuthContext();
  const { files, setFiles, changeLog } = useSubmissionMutateContext();

  const docRef = doc(collection(db, 'submissions'), id);
  const { data: record, status } = useFirestoreDocData(docRef, {
    idField: 'id',
  });

  const isLoaded = useMemo(() => status === 'success', [status]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  // TODO: Show error message

  const submitHandler = async (values: Submission) => {
    try {
      setIsLoading(true);

      // 1. Upload any new files to GCS
      if (files.length) {
        const prefix = `${record.tenantId}/${record.id}`;
        const fileUploadResults = await Promise.allSettled(
          files.map(async (f: File) => {
            try {
              const { name } = f;
              const filePath = `${prefix}/${name}`;
              const storageRef = ref(storage, filePath);
              const uploadResult = await uploadBytesResumable(storageRef, f);
              console.log(uploadResult);
              return true;
            } catch (er: any) {
              return false;
            }
          })
        );

        const didAllSucceed = fileUploadResults.every((it) => it);

        // do not save submission if file upload failed
        if (!didAllSucceed) {
          throw new Error('One or more files did not complete uploading');
        }
      }

      const batch = writeBatch(db);
      const date = convertLocalDateTimeToISO(DateTime.local());

      // 2. Prepare record updates
      const data = {
        ...values,
        isLocked: false,
        lockDateTime: null,
        lockedBy: null,
        updatedAt: date,
        isResubmission: values.didSubmit ? true : values.isResubmission,
      };
      batch.update(docRef, data);

      // 3. Prepare audit log
      const auditRef = doc(collection(db, 'auditlogs'));

      // 3a. Field changes
      const diffs = getDiffs(record, values);
      const fieldChanges = Object.keys(diffs).reduce((acc: string[], nx) => {
        const val = diffs[nx];
        const key = nx;
        return [...acc, `Changed ${key} to ${val}`];
      }, []);
      // 3b. New files
      const newFileChanges = files.reduce((acc: string[], nx) => {
        return [...acc, `Added file ${(nx as File).name}`];
      }, []);
      const auditData = {
        tenantId: values.tenantId,
        submissionId: id,
        dateTime: date,
        actionDetails: [...fieldChanges, ...changeLog, ...newFileChanges],
        userName: (userData as FBAuth.User).displayName,
      };
      batch.set(auditRef, auditData);

      // 4. Prepare new file records
      files.forEach((f) => {
        const { name } = f;
        const fDR = doc(collection(db, 'files'));
        batch.set(fDR, {
          tenantId: values.tenantId,
          submissionId: docRef.id,
          name,
          isArchived: false,
          createdAt: date,
          updatedAt: date,
        });
      });

      // 5. Commit all changes
      await batch.commit();

      setIsLoading(false);
      navigate(`/submission/${id}`);
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <Typography component="h1" variant="h1">
        Update Submission
      </Typography>
      {isAuthStateLoaded && isLoaded ? (
        <Formik
          initialValues={record as Submission}
          onSubmit={submitHandler}
          validationSchema={SubmissionSchema}
        >
          <>
            <SubmissionCountdown record={record as Submission} />
            <SubmissionForm mode={FormMode.UPDATE} />
            {(!isBroker ||
              (record.status !== SubmissionStatuses.REQUIRES_REVIEW.value &&
                record.status !== SubmissionStatuses.COMPLETE.value &&
                record.status !== SubmissionStatuses.IN_PROCESS.value)) && (
              <>
                <SubmissionFiles
                  mode={FormMode.UPDATE}
                  record={record as Submission}
                />
                <Box
                  className="section"
                  sx={{
                    minHeight: '48px',
                  }}
                >
                  <UploadInputWrapper
                    files={files}
                    setFiles={setFiles}
                    disabled={isLoading}
                    mode={FormMode.UPDATE}
                  />
                </Box>
                <SubmissionUpdateActions
                  isLoading={isLoading}
                  // record={record as Submission}
                />
              </>
            )}
          </>
        </Formik>
      ) : (
        <Loading />
      )}
    </Wrapper>
  );
}

export default SubmissionEdit;
