import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import { httpsCallableFromURL } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import FormikTextField from '../FormikTextField';
import FormikSelect from '../FormikSelect';
import Loading from '../Loading';
import { UserType } from '../../shared/enums';

const { REACT_APP_ADD_USER_FN_NAME } = process.env;

type BrokerFormProps = {
  error?: string;
};

const BrokerForm: FC<BrokerFormProps> = ({ error }) => {
  const { handleChange, values } = useFormikContext<User>();
  return (
    <Form id="addBrokerForm">
      <Box className="grid--form--modal">
        {!!error && (
          <Typography component="h4" variant="h4" color="error">
            {error}
          </Typography>
        )}
        <FormikTextField
          name="firstName"
          id="firstName"
          label="First Name"
          onChange={handleChange}
          value={values.firstName}
        />
        <FormikTextField
          name="lastName"
          id="lastName"
          label="Last Name"
          onChange={handleChange}
          value={values.lastName}
        />
        <FormikTextField
          type="tel"
          name="phone"
          id="phone"
          label="Phone"
          onChange={handleChange}
          value={values.phone}
        />
        <FormikTextField
          type="email"
          name="email"
          id="email"
          label="Email"
          onChange={handleChange}
          value={values.email}
        />
        <FormikSelect
          type="companySelect"
          name="tenantId"
          id="tenantId"
          label="Company"
          onChange={handleChange}
          value={values.tenantId}
        />
      </Box>
    </Form>
  );
};

type BrokerModalProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};

type FnCallableResult = {
  data: any;
};

const CreateBrokerModal: FC<BrokerModalProps> = ({ setIsOpen, isOpen }) => {
  const fns = useFunctions();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const createBrokerFn = httpsCallableFromURL(
    fns,
    REACT_APP_ADD_USER_FN_NAME as string
  );

  const initialValues: User = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    tenantId: '',
    isDeleted: false,
    type: UserType[UserType.Broker],
  };

  const submitHandler = async (values: User) => {
    setIsLoading(true);
    try {
      const result = await createBrokerFn(values);
      if (result && (result as unknown as FnCallableResult)?.data?.error) {
        const msg = (result as unknown as FnCallableResult)?.data?.error
          ?.errorInfo?.message;
        setError(msg);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const brokerSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(`Please enter the Broker's first name`)
      .min(2, 'Please enter at least 2 characters'),
    lastName: Yup.string()
      .required(`Please enter the Broker's last name`)
      .min(2, 'Please enter at least 2 characters'),
    phone: Yup.string()
      .required(`Please enter the Broker's phone number`)
      .matches(/\d{10}/g, 'Please provide a valid phone number'),
    email: Yup.string()
      .email()
      .required(`Please enter the Broker's email address`),
    tenantId: Yup.string().required(`Please select the Broker's company`),
  });

  return (
    <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
      <DialogTitle color="status.primary">Add a Broker</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={brokerSchema}
          enableReinitialize
        >
          <>
            {isLoading && <Loading />}
            {!isLoading && <BrokerForm error={error} />}
          </>
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          form="addBrokerForm"
          disabled={isLoading}
        >
          Save and Send invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBrokerModal;
