import React, { createContext, useContext, useMemo } from 'react';
import { collection, where, query, DocumentData } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

type StaffValue = {
  staff: DocumentData[];
  isLoading: boolean;
};

const initialState: StaffValue = {
  staff: [],
  isLoading: false,
};

const StaffContext = createContext(initialState);

type StaffProviderProps = {
  children: React.ReactNode;
};

export function StaffProvider(props: StaffProviderProps) {
  const { children } = props;

  const db = useFirestore();
  const constraints = useMemo(() => {
    const res = [where('isDeleted', '==', false), where('type', '==', 'Staff')];
    return res;
  }, []);

  const qStaff = query(collection(db, 'users'), ...constraints);

  const { status, data } = useFirestoreCollectionData(qStaff, {
    idField: 'id',
  });

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: StaffValue = useMemo(
    () => ({
      staff: data,
      isLoading,
    }),
    [isLoading, data]
  );

  return (
    <StaffContext.Provider value={getValue}>{children}</StaffContext.Provider>
  );
}

export const useStaffContext = () => useContext(StaffContext);
