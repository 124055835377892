import * as Yup from 'yup';

const BrokerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(`Please enter the Broker's first name`)
    .min(2, 'Please enter at least 2 characters'),
  lastName: Yup.string()
    .required(`Please enter the Broker's last name`)
    .min(2, 'Please enter at least 2 characters'),
  phone: Yup.string()
    .required(`Please enter the Broker's phone number`)
    .matches(
      /\d{10}/g,
      'Please provide a valid phone number including the area code'
    ),
  email: Yup.string()
    .email()
    .required(`Please enter the Broker's email address`),
});

export default BrokerSchema;
