import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import Select from '@mui/material/Select';
import { useBrokerContext } from '../../context/BrokerContext';

function BrokerSelect(props: SelectProps) {
  const {
    id,
    name,
    label,
    required,
    disabled,
    value,
    onChange,
    error,
    helperText,
  } = props;
  const { brokers } = useBrokerContext();
  return (
    <FormControl
      margin="dense"
      size="small"
      color="inputs"
      required={required}
      disabled={disabled}
      error={error}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        name={name}
        labelId={`${id}-label`}
        id={id}
        // autoWidth
        label="Originator"
        onChange={(e) => onChange(e)}
        value={value}
      >
        {brokers?.map((b) => (
          <MenuItem key={b.id} value={b.id}>
            {b.lastName}, {b.firstName}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default BrokerSelect;
