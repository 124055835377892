import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router-dom';
import Wrapper from '../../components/Layout/Wrapper';
import { useAuthContext } from '../../context/AuthContext';
import { useTenantContext } from '../../context/TenantContext';
import PageActions from '../../components/PageElements/PageActions';
import CreateCompanyModal from '../../components/Modal/CreateCompanyModal';

function CompaniesList() {
  const {
    claims: { admin },
    isAuthStateLoaded,
  } = useAuthContext();

  const { tenants, isLoading } = useTenantContext();

  const [isOpen, setIsOpen] = useState(false);

  const rows = useMemo(() => (isLoading ? [] : tenants), [tenants, isLoading]);

  const nameCheck = useMemo(
    () => rows.map((it) => it.name.toLowerCase()),
    [rows]
  );

  const handleOpen = () => setIsOpen(true);

  // only allow admins to access this page
  if (isAuthStateLoaded && !admin) return <Navigate to="/" />;

  const cols = [
    {
      field: 'name',
      headerName: 'Company Name',
      flex: 1,
      editable: false,
      headerClassName: 'table-heading--no-separator',
    },
  ];

  return (
    <Wrapper>
      <Typography component="h1" variant="h1">
        Companies
      </Typography>
      {!isAuthStateLoaded ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <PageActions
            rightActions={
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleOpen}
              >
                Add Company
              </Button>
            }
          />
          <DataGrid
            rows={rows}
            columns={cols}
            pagination
            autoHeight
            disableRowSelectionOnClick
            disableColumnMenu
            rowHeight={60}
            loading={isLoading}
          />
          <CreateCompanyModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            checkData={nameCheck}
          />
        </>
      )}
    </Wrapper>
  );
}
export default CompaniesList;
