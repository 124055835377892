import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import PageActions from './PageActions';
import { useSubmissionMutateContext } from '../../context/SubmissionMutateContext';
// import SubmitModal from '../Modal/SubmitModal';

type SubmissionCreateActionsProps = {
  isLoading: boolean;
};

const SubmissionCreateActions: FC<SubmissionCreateActionsProps> = ({
  isLoading,
}) => {
  const navigate = useNavigate();
  const { files } = useSubmissionMutateContext();
  // const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const { submitForm } = useFormikContext<Submission>();
  // const record = useFormikContext<Submission>();
  return (
    <PageActions
      rightActions={
        <>
          <Button
            type="button"
            disabled={isLoading}
            variant="text"
            color="secondary"
            onClick={async (e) => {
              e.preventDefault();
              navigate('/');
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={isLoading || !files.length}
            onClick={submitForm}
          >
            Save
          </Button>
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={isLoading || !files.length}
            onClick={() => setIsSubmitOpen(true)}
          >
            Save and send to analyzer
          </Button> */}
          {/* <SubmitModal
            isOpen={isSubmitOpen}
            setIsOpen={setIsSubmitOpen}
            submit
            submitForm={submitForm}
          /> */}
        </>
      }
    />
  );
};

export default SubmissionCreateActions;
