import React, { useState } from 'react';
import { Stack, TextField, Typography, Button, Box } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { RESET_EMAIL_MESSAGE } from '../../../shared/constants';
import { formatError } from '../../../utils/format';

const loginTextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#133463',
      color: '#133463',
    },
  },
  '& label.Mui-focused': {
    color: '#133463',
  },
};

function SentEmailMessage({ email, setEmailSent, setForgotPassword }) {
  const handleClickDone = () => {
    setEmailSent(false);
    setForgotPassword(false);
  };

  return (
    <Stack spacing="10px">
      <Typography align="left">
        Follow the instructions sent to
        <Box component="span" fontWeight="fontWeightMedium">
          {` ${email} `}
        </Box>
        to recover your password
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disableElevation variant="contained" onClick={handleClickDone}>
          Done
        </Button>
      </Box>
    </Stack>
  );
}

export default function ResetPassword({ setForgotPassword, email, auth }) {
  const [sendEmail, setSendEmail] = useState(email);
  const [emailSent, setEmailSent] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [sendErrorMessage, setSendErrorMessage] = useState('');

  const handleClickSend = async () => {
    try {
      await sendPasswordResetEmail(auth, sendEmail);
      setEmailSent(true);
      setSendError(false);
      setSendErrorMessage('');
    } catch (e) {
      setSendErrorMessage(formatError(e.code));
      setSendError(true);
    }
  };
  return !emailSent ? (
    <Stack spacing="24px">
      <Stack spacing="20px">
        <Typography align="left">{RESET_EMAIL_MESSAGE}</Typography>
        <TextField
          size="small"
          type="email"
          label="Email"
          sx={{
            ...loginTextFieldStyle,
            '.MuiFormHelperText-root': {
              color: 'red !important',
            },
          }}
          helperText={sendError ? sendErrorMessage : ''}
          value={sendEmail}
          onChange={(e) => {
            if (e.target.value === '') setSendError(false);
            setSendEmail(e.target.value);
          }}
        />
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <Button
          disableElevation
          variant="contained"
          onClick={() => setForgotPassword(false)}
        >
          Cancel
        </Button>
        <Button disableElevation variant="contained" onClick={handleClickSend}>
          Send
        </Button>
      </Box>
    </Stack>
  ) : (
    <SentEmailMessage
      email={sendEmail}
      setEmailSent={setEmailSent}
      setForgotPassword={setForgotPassword}
    />
  );
}
