import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { collection, where, query, orderBy, limit } from 'firebase/firestore';
import { httpsCallableFromURL } from 'firebase/functions';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from 'reactfire';
import { useAuthContext } from './AuthContext';

const { REACT_APP_GET_FILE_URL_FN_NAME } = process.env;

type SubmissionResultsValue = {
  files: SubmissionResult[];
  isLoading: boolean;
  getDownloadURL: (id: string) => Promise<string | null | undefined>;
};

const initialState: SubmissionResultsValue = {
  files: [],
  isLoading: false,
  getDownloadURL: async () => null,
};

const SubmissionResultsContext = createContext(initialState);

type SubmissionResultsProviderProps = {
  children: React.ReactNode;
};

type GetDLUrlResult = {
  url?: string;
  error?: string;
  status: boolean;
};

type FnCallableResult = {
  data: GetDLUrlResult;
};

export function SubmissionResultsProvider(
  props: SubmissionResultsProviderProps
) {
  const { children } = props;
  const db = useFirestore();
  const fns = useFunctions();
  const { id } = useParams();
  const {
    claims: { broker: isBroker, tenantId },
  } = useAuthContext();

  const queryParams = useMemo(() => {
    const params = [
      where('submissionId', '==', id),
      orderBy('dateTime', 'desc'),
    ];
    if (isBroker) {
      params.push(where('tenantId', '==', tenantId));
      params.push(limit(1));
    }
    return params;
  }, [id, isBroker, tenantId]);

  const q = query(collection(db, 'submissionresults'), ...queryParams);

  const { status, data } = useFirestoreCollectionData(q, {
    idField: 'id',
  });

  const fn = httpsCallableFromURL(
    fns,
    REACT_APP_GET_FILE_URL_FN_NAME as string
  );

  const getDownloadURL = useCallback(
    async (fileId: string) => {
      const file = data.find((it) => it.id === fileId);
      if (!file) return null;
      const result = await fn({
        filePath: `${file.tenantId}/${id}/results/${file.fileName}`,
        expirationTimeInMinutes: 120,
      });
      if (result && (result as unknown as FnCallableResult)) {
        return (result.data as GetDLUrlResult).url;
      }
      return null;
    },
    [data, fn, id]
  );

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: SubmissionResultsValue = useMemo(
    () => ({
      files:
        data?.map((it) => {
          return it as SubmissionResult;
        }) ?? [],
      isLoading,
      getDownloadURL,
    }),
    [data, isLoading, getDownloadURL]
  );

  return (
    <SubmissionResultsContext.Provider value={getValue}>
      {children}
    </SubmissionResultsContext.Provider>
  );
}

export const useSubmissionResultsContext = () =>
  useContext(SubmissionResultsContext);
