import * as React from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useConfirmDialogContext } from '../../context/DialogContext';

export default function AlertDialog({ title, message, onConfirm, isActing }) {
  const { isOpen, setIsOpen } = useConfirmDialogContext();

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {isActing ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="90px"
            minWidth="372px"
          >
            <CircularProgress variant="indeterminate" color="secondary" />
          </Box>
        ) : (
          <Alert severity="error">
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          color="secondary"
          disabled={isActing}
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={async () => {
            await onConfirm();
            setIsOpen(false);
          }}
          autoFocus
          disabled={isActing}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
}
