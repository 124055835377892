import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Wrapper from '../../components/Layout/Wrapper';
import SubmissionList from '../../components/SubmissionList/SubmissionList';
import { useAuthContext } from '../../context/AuthContext';
import { SubmissionsFilterProvider } from '../../context/SubmissionsFilterContext';

function Home() {
  const { isAuthStateLoaded } = useAuthContext();

  return (
    <Wrapper>
      <Typography component="h1" variant="h1">
        Submissions
      </Typography>
      {!isAuthStateLoaded ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <SubmissionsFilterProvider>
          <SubmissionList />
        </SubmissionsFilterProvider>
      )}
    </Wrapper>
  );
}

export default Home;
