import React, { useMemo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { getAuth, signOut } from 'firebase/auth';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthContext } from '../../context/AuthContext';
import Logo from './Logo';

function Nav() {
  const {
    claims: { broker, admin },
  } = useAuthContext();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { data: signInCheckResult } = useSigninCheck();
  const isSignedIn = useMemo(() => signInCheckResult, [signInCheckResult]);
  const auth = getAuth();
  const clickHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoggingOut(true);
      await signOut(auth);
      window.location = '/';
      setIsLoggingOut(false);
    },
    [auth]
  );
  return (
    <AppBar position="static" elevation={0} color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flex: '1 1 0',
            }}
          >
            <Logo />
          </Box>
          <Box className="nav--main" display="flex">
            {isSignedIn.signedIn && (
              <>
                <Button
                  key="Submissions"
                  component={Link}
                  color="light"
                  to="/"
                  disabled={isLoggingOut}
                >
                  Submissions
                </Button>
                {admin && (
                  <>
                    <Button
                      key="Companies"
                      component={Link}
                      color="light"
                      to="/companies"
                      disabled={isLoggingOut}
                    >
                      Companies
                    </Button>
                    <Button
                      key="Admin"
                      component={Link}
                      color="light"
                      to="/admin"
                      disabled={isLoggingOut}
                    >
                      Admin
                    </Button>
                    <Button
                      key="Staff"
                      component={Link}
                      color="light"
                      to="/staff"
                      disabled={isLoggingOut}
                    >
                      Staff
                    </Button>
                  </>
                )}
                {!broker && (
                  <Button
                    key="Brokers"
                    component={Link}
                    color="light"
                    to="/brokers"
                    disabled={isLoggingOut}
                  >
                    Brokers
                  </Button>
                )}

                <Box
                  sx={{
                    flex: '0 0 auto',
                    paddingLeft: '16px',
                    marginLeft: '16px',
                    borderLeftStyle: 'solid',
                    borderColor: '#fff',
                    borderLeftWidth: '2px',
                  }}
                >
                  <IconButton
                    key="Logout"
                    color="light"
                    aria-label="Log Out"
                    component="label"
                    onClick={clickHandler}
                    disabled={isLoggingOut}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Nav;
