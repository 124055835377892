import React, { useCallback } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  GridToolbarContainer,
  useGridApiContext,
  GridFilterItem,
} from '@mui/x-data-grid';
import { useSubmissionsFilterContext } from '../../context/SubmissionsFilterContext';
import { useAuthContext } from '../../context/AuthContext';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

function CustomToolbar() {
  const { isArchived, setIsArchived } = useSubmissionsFilterContext();
  const {
    claims: { staff: isStaff, admin: isAdmin },
  } = useAuthContext();
  const apiRef = useGridApiContext();
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setIsArchived(e.target.checked),
    [setIsArchived]
  );

  const updateFilter = (value: string) => {
    const gridFilterItem: GridFilterItem = {
      field: 'borrowerName',
      value,
      operator: 'contains',
    };
    apiRef.current.upsertFilterItem(gridFilterItem);
  };

  if (isStaff || isAdmin) {
    return (
      <GridToolbarContainer
        sx={{
          justifyContent: 'space-between',
          padding: '10px 1.5rem',
          backgroundColor: 'bg.tableToolbar',
        }}
      >
        <FormControlLabel
          label="Show archived submissions"
          control={
            <Switch
              name="isArchived"
              color="secondary"
              size="small"
              value={isArchived}
              onChange={onChange}
            />
          }
        />
        <TextField
          variant="standard"
          placeholder="Find Borrower"
          onChange={(event) => updateFilter(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </GridToolbarContainer>
    );
  }
  return null;
}

export default CustomToolbar;
