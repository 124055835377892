import React, { createContext, useContext, useMemo } from 'react';
import * as FbAuth from 'firebase/auth';
import { useUser, useIdTokenResult, useSigninCheck } from 'reactfire';

type AuthCtxProps = {
  children: React.ReactNode;
};

type AuthCtxValue = {
  userId?: string | null | undefined;
  userData?: FbAuth.User | null | undefined;
  claims: any;
  isAuthStateLoaded: boolean;
};

const initialState: AuthCtxValue = {
  userId: undefined,
  userData: undefined,
  claims: {},
  isAuthStateLoaded: false,
};

const AuthContext = createContext(initialState);

export function AppAuthProvider({ children }: AuthCtxProps) {
  const { status } = useSigninCheck();
  const { data: user, status: userStatus } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: idTokenData, status: idTokenStatus } = useIdTokenResult(user!);

  const isAuthStateLoaded = useMemo(
    () =>
      status === 'success' &&
      userStatus === 'success' &&
      idTokenStatus === 'success',
    [idTokenStatus, status, userStatus]
  );

  const getValue = useMemo(
    () => ({
      userId: user?.uid,
      userData: user,
      claims: idTokenData?.claims || {},
      isAuthStateLoaded,
    }),
    [isAuthStateLoaded, user, idTokenData]
  );

  return (
    <AuthContext.Provider value={getValue}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
