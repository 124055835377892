import { createTheme } from '@mui/material/styles';

const breakpoints = {
  values: { xs: 420, sm: 760, md: 960, lg: 1200, xl: 1400 },
};

const colors = {
  brandPrimary: '#dd5b30', // Brand orange
  brandSecondary: '#133463', // Brand blue
  grey: '#c0c0c0',
  light: '#ffffff', // White
  error: 'rgb(211, 47, 47)',
  warning: 'rgb(237, 108, 2)',
  success: 'rgb(46, 125, 50)',
};

const borderRadius = {
  s: 4,
  m: 8,
};

const defaultTheme = createTheme({
  spacing: 5,
  breakpoints,
  shape: { borderRadius: 4 },
});

const theme = createTheme({
  spacing: 5,
  breakpoints,
  borderRadius,
  shape: { borderRadius: 4 },
  palette: {
    primary: {
      main: colors.brandPrimary,
      dark: '#cd4c22',
      contrastText: '#fff',
      active: colors.brandSecondary,
      error: colors.error,
    },
    secondary: {
      main: colors.brandSecondary,
    },
    light: {
      main: colors.light,
    },
    grey: {
      100: colors.grey,
    },
    status: {
      error: colors.error,
      warning: colors.warning,
      success: colors.success,
    },
    bg: {
      tableToolbar: 'rgba(19, 52, 99, .1)', // brandSecondary at .1 opacity
      sectionHighlight: 'rgba(19, 52, 99, .1)', // brandSecondary at .1 opacity
    },
    inputs: {
      light: 'rgba(19, 52, 99, .6)', // brandSecondary at .6 opacity
      main: 'rgba(19, 52, 99, .8)', // brandSecondary at .8 opacity
      dark: 'rgba(19, 52, 99, 1)', // brandSecondary at 1 opacity
    },
  },
  typography: {
    fontFamily: 'Roboto, Sans-serif',
    h1: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.3,
      margin: '22px 0',
      textAlign: 'center',
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 19,
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    h5: {
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: 1,
      textTransform: 'uppercase',
      color: defaultTheme.palette.text.secondary,
    },
    button: { letterSpacing: 0.5 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: defaultTheme.spacing(1.5),
          paddingRight: defaultTheme.spacing(4.5),
          paddingBottom: defaultTheme.spacing(1.5),
          paddingLeft: defaultTheme.spacing(4.5),
          fontSize: 15,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: 'rgba(19, 52, 99, .1)', // brandSecondary at .1 opacity,
            '&:hover': {
              backgroundColor: 'rgba(19, 52, 99, .1)', // brandSecondary at .1 opacity,
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('xs')]: {
            paddingLeft: defaultTheme.spacing(5),
            paddingRight: defaultTheme.spacing(5),
          },
        },
      },
    },
  },
});

export default theme;
