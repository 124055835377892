import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import * as FBAuth from 'firebase/auth';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useFirestore, useStorage } from 'reactfire';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Wrapper from '../../components/Layout/Wrapper';
import SubmissionForm from '../../components/Forms/SubmissionForm';
import SubmissionCreateActions from '../../components/PageElements/SubmissionCreateActions';
import Loading from '../../components/Loading';
import SubmissionSchema from '../../schema/Submission';
import { useSubmissionMutateContext } from '../../context/SubmissionMutateContext';
import { useAuthContext } from '../../context/AuthContext';
import { SubmissionStatuses } from '../../shared/constants';
import { FormMode } from '../../shared/enums';
import { convertLocalDateTimeToISO } from '../../utils/dateUtils';
import UploadInputWrapper from '../../components/UploadInputWrapper';

const initialValues = (
  tenantId?: string,
  brokerId?: string | null,
  staffId?: string | null
): Submission => ({
  tenantId: tenantId || '',
  brokerId: brokerId || '',
  staffId: staffId || '',
  borrowerFirstName: '',
  borrowerLastName: '',
  loanProgram: '',
  status: SubmissionStatuses.INITIAL_SUBMISSION.value,
  companyName: '',
  bankStatementsType: '',
  businessExpenseFactor: '',
  businessExpenseFactorPercentage: undefined,
  profitAndLossExpenseFactorPercentage: undefined,
  businessNarrative: '',
  businessOwnershipPercentage: 0,
  hasThreeMonthsBusinessBankStatements: false,
  incompleteReason: '',
  comments: undefined,
  isLocked: false,
  isResubmission: false,
  didSubmit: false,
  isArchived: false,
  createdAt: undefined,
  updatedAt: undefined,
});

function SubmissionCreate() {
  const {
    userId,
    userData,
    claims: { tenantId, broker: isBroker },
    isAuthStateLoaded,
  } = useAuthContext();
  const db = useFirestore();
  const storage = useStorage();
  const navigate = useNavigate();
  const {
    files,
    setFiles,
    docRef: submissionRef,
  } = useSubmissionMutateContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  // TODO: Show error message

  const submitHandler = async (values: Submission) => {
    try {
      setIsLoading(true);
      const date = convertLocalDateTimeToISO(DateTime.local());
      const { tenantId: tId } = values;

      // 1. Upload files to GCS
      const prefix = `${tId}/${submissionRef.id}`;
      const fileUploadResults = await Promise.allSettled(
        files.map(async (f: File) => {
          try {
            const { name } = f;
            const filePath = `${prefix}/${name}`;
            const storageRef = ref(storage, filePath);
            const uploadResult = await uploadBytesResumable(storageRef, f);
            console.log(uploadResult);
            return true;
          } catch (er: any) {
            return false;
          }
        })
      );

      const didAllSucceed = fileUploadResults.every((it) => it);

      // do not save submission if file upload failed
      if (!didAllSucceed) {
        throw new Error('One or more files did not complete uploading');
      }

      const batch = writeBatch(db);

      // 2. Prepare new record
      const docRef = doc(collection(db, 'submissions'), submissionRef.id);
      const data = {
        ...values,
        borrowerFirstName: values.borrowerFirstName.trim(),
        borrowerLastName: values.borrowerLastName.trim(),
        companyName: values.companyName?.trim() || '',
        businessExpenseFactorPercentage:
          values.businessExpenseFactorPercentage || '', // firestore doesn't accept undefined as a value
        profitAndLossExpenseFactorPercentage:
          values.profitAndLossExpenseFactorPercentage || '', // firestore doesn't accept undefined as a value
        comments: values.comments || '', // firestore doesn't accept undefined as a value
        createdAt: date,
        updatedAt: date,
      };
      batch.set(docRef, data);
      const sendRef = data;
      sendRef.id = docRef.id;
      // 3. Prepare new file records
      files.forEach((f) => {
        const { name } = f;
        const fDR = doc(collection(db, 'files'));
        batch.set(fDR, {
          tenantId: tId,
          submissionId: docRef.id,
          name,
          isArchived: false,
          createdAt: date,
          updatedAt: date,
        });
      });

      // 4. Prepare create audit log
      const auditRef = doc(collection(db, 'auditlogs'));

      const newFileAdditions = files.reduce((acc: string[], nx) => {
        return [...acc, `Added file ${(nx as File).name}`];
      }, []);

      const auditData = {
        tenantId: tId,
        submissionId: docRef.id,
        dateTime: date,
        actionDetails: ['Created submission', ...newFileAdditions],
        userName: (userData as FBAuth.User).displayName,
      };
      batch.set(auditRef, auditData);

      // 5. Commit all changes
      await batch.commit();

      setIsLoading(false);
      navigate('/');
      return sendRef;
    } catch (e: any) {
      setIsLoading(false);
      setError(e?.message);
      return {};
    }
  };

  return (
    <Wrapper>
      <>
        <Typography component="h1" variant="h1">
          Create Submission
        </Typography>
        {isAuthStateLoaded ? (
          <Formik
            initialValues={initialValues(
              isBroker ? tenantId : undefined,
              isBroker ? userId : undefined
            )}
            onSubmit={submitHandler}
            validationSchema={SubmissionSchema}
          >
            <>
              {isLoading && <Loading />}
              <SubmissionForm />
              {/* <UploadFiles formMode={FormMode.CREATE} /> */}
              <Box
                className="section"
                sx={{
                  minHeight: '48px',
                }}
              >
                <UploadInputWrapper
                  files={files}
                  setFiles={setFiles}
                  disabled={isLoading}
                  mode={FormMode.CREATE}
                />
              </Box>
              <SubmissionCreateActions isLoading={isLoading} />
            </>
          </Formik>
        ) : (
          <Loading />
        )}
      </>
    </Wrapper>
  );
}

export default SubmissionCreate;
