import React from 'react';

const Logo = () => (
  <svg className="logo" viewBox="0 0 989.48 112.58">
    <circle className="primary" cx="53.74" cy="94.28" r="5.53" />
    <circle className="primary" cx="53.77" cy="79.09" r="5.53" />
    <circle className="primary" cx="53.77" cy="63.89" r="5.53" />
    <circle className="primary" cx="53.77" cy="48.69" r="5.53" />
    <circle className="primary" cx="53.77" cy="33.49" r="5.53" />
    <circle className="primary" cx="53.77" cy="18.3" r="5.53" />
    <circle className="primary" cx="40.19" cy="26.73" r="5.53" />
    <circle className="primary" cx="40.19" cy="55.58" r="5.53" />
    <circle className="primary" cx="26.47" cy="55.58" r="5.53" />
    <circle className="primary" cx="40.19" cy="84.43" r="5.53" />
    <rect
      className="primary"
      x="24.16"
      y="52"
      width="87.11"
      height="8.59"
      transform="translate(11.43 124) rotate(-90)"
    />
    <path
      className="primary"
      d="M154.1,26.61h-49.18v22.35h43.59v13.79h-43.59v23.22h49.8v13.79H89.77V12.82h64.33v13.79Z"
    />
    <path
      className="primary"
      d="M208.12,78.39h-.5l-27.2-40.73v62.09h-15.15V12.82h16.27l26.45,41.11,26.45-41.11h16.27V99.75h-15.15V37.41l-27.45,40.98Z"
    />
    <path
      className="primary"
      d="M325.81,54.92c-1.83,3.73-4.32,6.83-7.47,9.31-3.15,2.48-6.85,4.37-11.08,5.65-4.23,1.28-8.76,1.92-13.57,1.92h-17.3v27.94h-15.15V12.82h34.28c5.05,0,9.62,.68,13.72,2.05,4.1,1.37,7.58,3.33,10.43,5.9,2.86,2.57,5.05,5.63,6.58,9.19,1.53,3.56,2.3,7.58,2.3,12.05,0,4.89-.91,9.19-2.74,12.92Zm-17.89-24.39c-3.41-2.62-7.98-3.93-13.71-3.93h-17.82v31.42h17.82c5.81,0,10.4-1.43,13.77-4.3,3.36-2.87,5.05-6.67,5.05-11.41,0-5.24-1.7-9.16-5.11-11.78Z"
    />
    <path
      className="primary"
      d="M422.81,73.55c-2.23,5.46-5.38,10.25-9.44,14.34-4.06,4.1-8.86,7.35-14.41,9.75-5.55,2.4-11.67,3.6-18.38,3.6s-12.83-1.18-18.38-3.54c-5.55-2.36-10.31-5.57-14.28-9.62-3.97-4.06-7.08-8.82-9.31-14.28-2.24-5.46-3.35-11.26-3.35-17.39s1.12-11.92,3.35-17.39c2.23-5.46,5.38-10.25,9.44-14.34,4.06-4.1,8.86-7.35,14.41-9.75,5.55-2.4,11.67-3.6,18.38-3.6s12.83,1.18,18.38,3.54c5.55,2.36,10.31,5.57,14.28,9.62,3.97,4.06,7.08,8.82,9.31,14.28,2.24,5.46,3.35,11.26,3.35,17.39s-1.12,11.92-3.35,17.39Zm-14.84-29.12c-1.45-3.77-3.48-7.06-6.08-9.87-2.61-2.81-5.73-5.05-9.38-6.71-3.64-1.66-7.62-2.48-11.92-2.48s-8.26,.81-11.86,2.42c-3.6,1.61-6.69,3.81-9.25,6.58-2.57,2.77-4.58,6.04-6.02,9.81-1.45,3.77-2.17,7.76-2.17,11.98s.72,8.22,2.17,11.98c1.45,3.77,3.48,7.06,6.08,9.87,2.61,2.82,5.73,5.05,9.38,6.71,3.64,1.66,7.62,2.48,11.92,2.48s8.26-.81,11.86-2.42c3.6-1.61,6.68-3.81,9.25-6.58,2.57-2.77,4.57-6.04,6.02-9.81,1.45-3.77,2.17-7.76,2.17-11.98s-.73-8.22-2.17-11.98Z"
    />
    <path
      className="primary"
      d="M492.54,99.75l-21.43-30.3h-19.18v30.3h-15.15V12.82h38.75c4.97,0,9.46,.64,13.47,1.92,4.01,1.28,7.41,3.1,10.18,5.46,2.77,2.36,4.9,5.26,6.4,8.69,1.49,3.44,2.24,7.27,2.24,11.49,0,3.64-.52,6.89-1.55,9.75-1.04,2.86-2.46,5.38-4.28,7.58-1.82,2.19-4,4.06-6.52,5.59-2.53,1.53-5.32,2.71-8.38,3.54l23.47,32.91h-18.01Zm-4.98-69.47c-3.16-2.45-7.6-3.68-13.33-3.68h-22.3v29.31h22.42c5.48,0,9.84-1.33,13.08-3.99,3.24-2.66,4.86-6.24,4.86-10.73,0-4.82-1.58-8.46-4.73-10.91Z"
    />
    <path
      className="primary"
      d="M627.14,61.44c0,6.46-.89,12.13-2.67,17.01-1.78,4.89-4.28,8.96-7.51,12.23-3.23,3.27-7.14,5.71-11.74,7.33-4.59,1.61-9.75,2.42-15.46,2.42s-10.72-.81-15.28-2.42c-4.55-1.61-8.44-4.04-11.67-7.26-3.23-3.23-5.71-7.22-7.45-11.98-1.74-4.76-2.61-10.29-2.61-16.58V12.14h15.15V61.57c0,8.12,1.96,14.28,5.87,18.5,3.91,4.22,9.32,6.33,16.24,6.33s12.2-2.03,16.11-6.08c3.91-4.06,5.87-10.1,5.87-18.13V12.14h15.15V61.44Z"
    />
    <path
      className="primary"
      d="M680.52,78.39h-.5l-27.2-40.73v62.09h-15.15V12.82h16.27l26.45,41.11,26.45-41.11h16.27V99.75h-15.15V37.41l-27.45,40.98Z"
    />
    <g>
      <path className="primary" d="M537.47,43.8v56.06h-15.31V43.8h15.31Z" />
      <circle className="secondary" cx="529.82" cy="23.95" r="12.31" />
    </g>
    <polygon
      className="primary"
      points="733.76 13.05 733.76 20.95 763.16 20.95 763.16 99.53 771.55 99.53 771.55 20.95 800.96 20.95 800.96 13.05 733.76 13.05"
    />
    <path
      className="primary"
      d="M843.15,13.05h-31.67V99.53h8.39v-31.46h21.72c23.46,0,33.96-14.03,33.96-27.93v-.24c0-16.31-12.72-26.85-32.39-26.85Zm-23.28,7.91h22.8c15.33,0,24.49,7.22,24.49,19.31v.24c0,11.77-10.13,19.67-25.21,19.67h-22.08V20.95Z"
    />
    <path
      className="primary"
      d="M968.45,55.73c-.16-25.18-18.75-44.13-43.36-44.13-27.29,0-43.6,22.72-43.6,44.69v.24c0,25.34,18.64,44.45,43.36,44.45,27.16,0,43.44-22.5,43.6-44.37,.05-.12,.09-.27,.09-.44s-.04-.32-.09-.44Zm-8.63,.56v.24c0,20.9-14.93,36.66-34.73,36.66s-34.97-16.21-34.97-36.9v-.24c0-20.9,14.93-36.66,34.73-36.66s34.97,16.21,34.97,36.9Z"
    />
  </svg>
);

export default Logo;
