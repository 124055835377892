import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSigninCheck, SuspenseWithPerf } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Home from '../../views/Home';
import Login from '../../views/Login';
import BrokerList from '../../views/BrokerList';
import CompaniesList from '../../views/CompaniesList';
import StaffList from '../../views/StaffList';
import AdminList from '../../views/AdminList';
import SubmissionCreate from '../../views/SubmissionCreate';
import SubmissionEdit from '../../views/SubmissionEdit';
import SubmissionView from '../../views/SubmissionView';
import { AppAuthProvider } from '../../context/AuthContext';
import { TenantProvider } from '../../context/TenantContext';
import { BrokerProvider } from '../../context/BrokerContext';
import { StaffProvider } from '../../context/StaffContext';
import { AdminProvider } from '../../context/AdminContext';
import { SubmissionMutateProvider } from '../../context/SubmissionMutateContext';
import { SubmissionFilesProvider } from '../../context/SubmissionFilesContext';

function AuthWrapper({ children, fallback }) {
  const { data: signInCheckResult, status } = useSigninCheck();

  if (status === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (signInCheckResult?.signedIn) {
    return children;
  }
  return fallback;
}

function Root() {
  return (
    <SuspenseWithPerf
      traceId="firebase-user-wait"
      fallback={<CircularProgress />}
    >
      <Routes>
        {/* Login page */}
        <Route path="/login" element={<Login />} />
        {/* List of submissions */}
        <Route
          path="/"
          element={
            <AuthWrapper fallback={<Navigate to="/login" />}>
              <AppAuthProvider>
                <TenantProvider>
                  <BrokerProvider>
                    <StaffProvider>
                      <AdminProvider>
                        <Home />
                      </AdminProvider>
                    </StaffProvider>
                  </BrokerProvider>
                </TenantProvider>
              </AppAuthProvider>
            </AuthWrapper>
          }
        />
        <Route path="/submission/">
          {/* Submission view */}
          <Route
            path=":id/"
            element={
              <AuthWrapper fallback={<Navigate to="/login" />}>
                <AppAuthProvider>
                  <TenantProvider>
                    <BrokerProvider>
                      <StaffProvider>
                        <AdminProvider>
                          <SubmissionView />
                        </AdminProvider>
                      </StaffProvider>
                    </BrokerProvider>
                  </TenantProvider>
                </AppAuthProvider>
              </AuthWrapper>
            }
          />
          {/* Submission edit */}
          <Route
            path=":id/update/"
            element={
              <AuthWrapper fallback={<Navigate to="/login" />}>
                <AppAuthProvider>
                  <TenantProvider>
                    <BrokerProvider>
                      <StaffProvider>
                        <AdminProvider>
                          <SubmissionMutateProvider>
                            <SubmissionFilesProvider>
                              <SubmissionEdit />
                            </SubmissionFilesProvider>
                          </SubmissionMutateProvider>
                        </AdminProvider>
                      </StaffProvider>
                    </BrokerProvider>
                  </TenantProvider>
                </AppAuthProvider>
              </AuthWrapper>
            }
          />
          {/* Submission create */}
          <Route
            exact
            path="create/"
            element={
              <AuthWrapper fallback={<Navigate to="/login" />}>
                <AppAuthProvider>
                  <TenantProvider>
                    <BrokerProvider>
                      <StaffProvider>
                        <AdminProvider>
                          <SubmissionMutateProvider>
                            <SubmissionCreate />
                          </SubmissionMutateProvider>
                        </AdminProvider>
                      </StaffProvider>
                    </BrokerProvider>
                  </TenantProvider>
                </AppAuthProvider>
              </AuthWrapper>
            }
          />
        </Route>
        {/* brokers */}
        <Route
          path="/brokers"
          element={
            <AuthWrapper fallback={<Navigate to="/" />}>
              <AppAuthProvider>
                <TenantProvider>
                  <BrokerProvider>
                    <BrokerList />
                  </BrokerProvider>
                </TenantProvider>
              </AppAuthProvider>
            </AuthWrapper>
          }
        />
        {/* staff */}
        <Route
          path="/staff"
          element={
            <AuthWrapper fallback={<Navigate to="/" />}>
              <AppAuthProvider>
                <TenantProvider>
                  <StaffProvider>
                    <StaffList />
                  </StaffProvider>
                </TenantProvider>
              </AppAuthProvider>
            </AuthWrapper>
          }
        />
        {/* Admin */}
        <Route
          path="/admin"
          element={
            <AuthWrapper fallback={<Navigate to="/" />}>
              <AppAuthProvider>
                <TenantProvider>
                  <AdminProvider>
                    <AdminList />
                  </AdminProvider>
                </TenantProvider>
              </AppAuthProvider>
            </AuthWrapper>
          }
        />
        <Route
          path="/companies"
          element={
            <AuthWrapper fallback={<Navigate to="/" />}>
              <AppAuthProvider>
                <TenantProvider>
                  <CompaniesList />
                </TenantProvider>
              </AppAuthProvider>
            </AuthWrapper>
          }
        />
        {/* The below redirects all unrecognized paths to the home page. */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </SuspenseWithPerf>
  );
}

export default Root;
