import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { collection, where, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useAuthContext } from './AuthContext';

type AuditLogValue = {
  logs: AuditLog[];
  isLoading: boolean;
};

const initialState: AuditLogValue = {
  logs: [],
  isLoading: false,
};

const AuditLogContext = createContext(initialState);

type AuditLogProviderProps = {
  children: React.ReactNode;
};

export function AuditLogProvider(props: AuditLogProviderProps) {
  const { children } = props;
  const db = useFirestore();
  const { id } = useParams();
  const {
    claims: { broker: isBroker, tenantId },
  } = useAuthContext();

  const constraints = useMemo(() => {
    const res = [where('submissionId', '==', id)];
    if (isBroker) {
      res.push(where('tenantId', '==', tenantId));
    }
    return res;
  }, [isBroker, tenantId, id]);

  const q = query(collection(db, 'auditlogs'), ...constraints);

  const { status, data } = useFirestoreCollectionData(q, {
    idField: 'id',
  });

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: AuditLogValue = useMemo(
    () => ({
      logs: data?.map((it) => it as AuditLog) ?? [],
      isLoading,
    }),
    [data, isLoading]
  );

  return (
    <AuditLogContext.Provider value={getValue}>
      {children}
    </AuditLogContext.Provider>
  );
}

export const useAuditLogContext = () => useContext(AuditLogContext);
