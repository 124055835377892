import React, { useMemo, useState, useCallback } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { doc, collection, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { SubmissionStatuses } from '../../shared/constants';
import { useAuthContext } from '../../context/AuthContext';
import { useBrokerContext } from '../../context/BrokerContext';
import { AuditLogProvider } from '../../context/AuditLogContext';
import PageActions from '../PageElements/PageActions';
import AuditLogModal from '../Modal/AuditLogModal';
import ArchiveSubmissionModal from '../Modal/ArchiveSubmissionModal';
// import SubmitModal from '../Modal/SubmitModal';
import SubmissionFields from './SubmissionFields';
import SubmissionFiles from './SubmissionFiles';
import SubmissionResults from './SubmissionResults';
import { convertLocalDateTimeToISO } from '../../utils/dateUtils';
import { useStaffContext } from '../../context/StaffContext';

function LeftActions() {
  const {
    claims: { admin: isAdmin },
  } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const doPrint = () => {
    window.print();
  };

  return (
    <>
      {isAdmin && (
        <>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            onClick={() => setIsOpen(true)}
          >
            Audit Log
          </Button>
          <AuditLogProvider>
            <AuditLogModal isOpen={isOpen} setIsOpen={setIsOpen} />
          </AuditLogProvider>
        </>
      )}
      <Button
        variant="outlined"
        disableElevation
        color="secondary"
        onClick={doPrint}
      >
        Print PDF
      </Button>
    </>
  );
}

function RightActions({ id, record }) {
  const db = useFirestore();
  const navigate = useNavigate();
  // const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    userData,
    claims: { broker: isBroker },
  } = useAuthContext();

  const { status, isLocked, lockedBy, lockDateTime } = record;

  const handleEditClick = useCallback(async () => {
    const url = `/submission/${id}/update/`;
    const docRef = doc(collection(db, 'submissions'), id);
    const nowDT = DateTime.local();
    if (isLocked) {
      if (lockedBy === userData.displayName) {
        navigate(url);
      } else {
        const lockDT = DateTime.fromISO(lockDateTime).setZone(
          DateTime.local().zoneName
        );
        const diff = lockDT.diff(nowDT, ['minutes']);
        if (diff.minutes > 12 || diff.minutes < -12) {
          await updateDoc(docRef, {
            lockDateTime: convertLocalDateTimeToISO(nowDT),
            isLocked: true,
            lockedBy: userData.displayName,
          });
          navigate(url);
        } else {
          setIsOpen(true);
        }
      }
    } else {
      await updateDoc(docRef, {
        lockDateTime: convertLocalDateTimeToISO(nowDT),
        isLocked: true,
        lockedBy: userData.displayName,
      });
      navigate(url);
    }
  }, [setIsOpen, db, id, isLocked, lockDateTime, navigate, userData, lockedBy]);

  return (
    <>
      {status !== SubmissionStatuses.INCOMPLETE.value && (
        <>
          <Button
            variant="contained"
            disableElevation
            color="error"
            onClick={() => setIsArchiveOpen(true)}
          >
            Archive
          </Button>
          <ArchiveSubmissionModal
            isOpen={isArchiveOpen}
            setIsOpen={setIsArchiveOpen}
          />
        </>
      )}
      <>
        {(!isBroker ||
          (status !== SubmissionStatuses.REQUIRES_REVIEW.value &&
            status !== SubmissionStatuses.COMPLETE.value &&
            status !== SubmissionStatuses.IN_PROCESS.value)) && (
          <Button
            variant="outlined"
            disableElevation
            color="secondary"
            onClick={() => handleEditClick()}
          >
            Edit
          </Button>
        )}
        <Dialog open={isOpen} maxWidth="sm" scroll="paper" fullWidth>
          <DialogTitle color="status.primary">Submission Locked</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              This submission is currently being edited by {lockedBy}.
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              padding: '10px 24px 20px 24px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {/* {status !== SubmissionStatuses.IN_PROCESS.value && (
        <>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => setIsSubmitOpen(true)}
          >
            {status === SubmissionStatuses.INITIAL_SUBMISSION.value
              ? 'Send to analyzer'
              : 'Resend to analyzer'}
          </Button>
          <SubmitModal
            isOpen={isSubmitOpen}
            setIsOpen={setIsSubmitOpen}
            record={record}
            id={record.id}
          />
        </>
      )} */}
    </>
  );
}

function SubmissionDetails() {
  const db = useFirestore();
  const { id } = useParams();

  const { brokers } = useBrokerContext();
  const { staff } = useStaffContext();

  const docRef = doc(collection(db, 'submissions'), id);
  const { data: record, status } = useFirestoreDocData(docRef, {
    idField: 'id',
  });

  const isLoaded = useMemo(() => status === 'success', [status]);
  const isLoading = useMemo(() => status === 'loading', [status]);
  const isLoadingError = useMemo(() => status === 'error', [status]);

  const brokerData = useMemo(() => {
    const broker = isLoaded
      ? brokers?.find((it) => it.id === record.brokerId)
      : {};
    if (broker) {
      return {
        firstName: broker.firstName,
        lastName: broker.lastName,
        email: broker.email,
        phone: broker.phone,
      };
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
  }, [brokers, isLoaded, record]);

  const staffData = useMemo(() => {
    const staffD = isLoaded
      ? staff?.find((it) => it.id === record.staffId)
      : {};
    if (staffD) {
      return {
        firstName: staffD.firstName,
        lastName: staffD.lastName,
        email: staffD.email,
        phone: staffD.phone,
      };
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
  }, [isLoaded, record, staff]);

  if (isLoadingError) return <Navigate to="/" />;

  return (
    <>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      )}
      {isLoaded && (
        <>
          <Typography component="h1" variant="h1">
            Submission Details
          </Typography>

          <PageActions
            leftActions={<LeftActions />}
            rightActions={<RightActions id={id} record={record} />}
          />
          <SubmissionFields
            record={record}
            broker={brokerData}
            isLoaded={isLoaded}
            staff={staffData}
          />
          <SubmissionFiles record={record} />
          <SubmissionResults record={record} />
        </>
      )}
    </>
  );
}

export default SubmissionDetails;
