import React, { FC, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useAuthContext } from '../../context/AuthContext';
import {
  BankStatementTypeOptions,
  BusinessExpenseFactorOptions,
  LoanProgramOptions,
  SubmissionStatuses,
} from '../../shared/constants';

type SubmissionFieldProps = {
  record: Submission;
  broker: User;
  staff: User;
  isLoaded: boolean;
};

const SubmissionFields: FC<SubmissionFieldProps> = ({
  isLoaded,
  record,
  broker,
  staff,
}) => {
  const {
    claims: { broker: isBroker },
  } = useAuthContext();

  const statusText = useMemo(() => {
    if (!isLoaded) return 'N/A';

    if (
      isBroker &&
      record.status === SubmissionStatuses.REQUIRES_REVIEW.value
    ) {
      return SubmissionStatuses.IN_PROCESS.label;
    }

    return (
      SubmissionStatuses[record.status as keyof typeof SubmissionStatuses]
        ?.label ?? 'N/A'
    );
  }, [isBroker, isLoaded, record.status]);

  return isLoaded ? (
    <>
      <Box className="section-highlight">
        <Typography component="h2" variant="h2">
          Status
        </Typography>

        <Box className="grid--section">
          <Box>
            <Typography variant="caption" color="secondary">
              Status
            </Typography>
            <Typography variant="body1">{statusText}</Typography>
          </Box>

          {record.status === SubmissionStatuses.INCOMPLETE.value && (
            <Box>
              <Typography variant="caption" color="secondary">
                Incomplete Reason
              </Typography>
              <Typography variant="body1">
                {record.incompleteReason || 'N/A'}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography variant="caption" color="secondary">
              Archived
            </Typography>
            <Typography variant="body1">
              {record.isArchived ? 'Yes' : 'No'}
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Analyzer Submission Number
            </Typography>
            <Typography variant="body1">
              {record.analyzerLoanNumber || 'N/A'}
            </Typography>
          </Box>

          {!isBroker && (
            <Box>
              <Typography variant="caption" color="secondary">
                Analyzer File URL
              </Typography>
              <Typography variant="body1">
                {record.analyzerUrl ? (
                  <Link
                    href={record.analyzerUrl}
                    target="blank"
                    rel="noreferrer"
                  >
                    {record.analyzerUrl}
                  </Link>
                ) : (
                  'N/A'
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="section-highlight">
        <Typography component="h2" variant="h2">
          Originator Information
        </Typography>

        <Box className="grid--section">
          <Box>
            <Typography variant="caption" color="secondary">
              Name
            </Typography>
            <Typography variant="body1">
              {broker.lastName},&nbsp;{broker.firstName}
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Email
            </Typography>
            <Typography variant="body1">{broker.email}</Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Phone
            </Typography>
            <Typography variant="body1">{broker.phone}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className="section-highlight">
        <Typography component="h2" variant="h2">
          Borrower Information
        </Typography>

        <Box className="grid--section">
          <Box>
            <Typography variant="caption" color="secondary">
              Name
            </Typography>
            <Typography variant="body1">
              {record.borrowerLastName},&nbsp;{record.borrowerFirstName}
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Account Executive
            </Typography>
            <Typography variant="body1">
              {staff.lastName},&nbsp;{staff.firstName}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="section-highlight">
        <Typography component="h2" variant="h2">
          Loan Information
        </Typography>

        <Box className="grid--section">
          <Box>
            <Typography variant="caption" color="secondary">
              Bank Statement Type
            </Typography>
            <Typography variant="body1">
              {
                BankStatementTypeOptions[
                  record.bankStatementsType as keyof typeof BankStatementTypeOptions
                ]?.label
              }
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Comments
            </Typography>
            <Typography variant="body1">{record.comments}</Typography>
          </Box>
        </Box>
      </Box>

      <Box className="section-highlight">
        <Typography component="h2" variant="h2">
          Business Information
        </Typography>

        <Box className="grid--section">
          <Box>
            <Typography variant="caption" color="secondary">
              Company Name
            </Typography>
            <Typography variant="body1">{record.companyName}</Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Business Ownership Percentage
            </Typography>
            <Typography variant="body1">
              {record.businessOwnershipPercentage}
              {record.businessOwnershipPercentage ? '%' : ''}
            </Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="secondary">
              Loan Program
            </Typography>
            <Typography variant="body1">
              {
                LoanProgramOptions[
                  record.loanProgram as keyof typeof LoanProgramOptions
                ]?.label
              }
            </Typography>
          </Box>

          {BankStatementTypeOptions[
            record.bankStatementsType as keyof typeof BankStatementTypeOptions
          ]?.value === 'PERSONAL' && (
            <Box>
              <Typography variant="caption" color="secondary">
                2 Months Business Bank Statements Available
              </Typography>
              <Typography variant="body1">
                {record.hasThreeMonthsBusinessBankStatements ? 'Yes' : 'No'}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography variant="caption" color="secondary">
              Business Expense Factor
            </Typography>
            <Typography variant="body1">
              {
                BusinessExpenseFactorOptions[
                  record.businessExpenseFactor as keyof typeof BusinessExpenseFactorOptions
                ]?.label
              }
            </Typography>
          </Box>

          {BusinessExpenseFactorOptions[
            record.businessExpenseFactor as keyof typeof BusinessExpenseFactorOptions
          ]?.value === 'TAXORCPA' && (
            <Box>
              <Typography variant="caption" color="secondary">
                Business Expense Factor Percentage
              </Typography>
              <Typography variant="body1">
                {record.businessExpenseFactorPercentage}
                {record.businessExpenseFactorPercentage ? '%' : ''}
              </Typography>
            </Box>
          )}

          {BusinessExpenseFactorOptions[
            record.businessExpenseFactor as keyof typeof BusinessExpenseFactorOptions
          ]?.value === 'THIRDPARTYPL' && (
            <Box>
              <Typography variant="caption" color="secondary">
                P&amp;L Expense Factor Percentage
              </Typography>
              <Typography variant="body1">
                {record.profitAndLossExpenseFactorPercentage}
                {record.profitAndLossExpenseFactorPercentage ? '%' : ''}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography variant="caption" color="secondary">
              Business Narrative
            </Typography>
            <Typography variant="body1">{record.businessNarrative}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};

export default SubmissionFields;
