import React from 'react';
import TextField from '@mui/material/TextField';

function ETextField(props: TextFieldProps) {
  const {
    id,
    label,
    type,
    required,
    disabled,
    value,
    multiline,
    maxRows,
    onChange,
    inputProps,
    error,
    helperText,
  } = props;
  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      size="small"
      margin="dense"
      required={required}
      disabled={disabled}
      onChange={onChange}
      value={value}
      color="inputs"
      multiline={multiline}
      maxRows={maxRows}
      type={type || 'text'}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
    />
  );
}

export default ETextField;
