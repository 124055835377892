import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { ThemeProvider as GlobalThemeProvider } from '@mui/system';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import theme from '../../Theme/theme';
import firebaseApp from '../../firebaseCommon/firebaseApp';
import FirebaseSDKProviders from '../FirebaseSDKProviders';
import GlobalStyles from '../GlobalStyles';
import Root from '../Root';

function App() {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <FirebaseSDKProviders>
        <GlobalThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <Router basename={process.env.PUBLIC_URL || '/'}>
                <ThemeProvider theme={theme}>
                  <GlobalStyles />
                  <CssBaseline />
                  <Root />
                </ThemeProvider>
              </Router>
            </StyledEngineProvider>
          </ThemeProvider>
        </GlobalThemeProvider>
      </FirebaseSDKProviders>
    </FirebaseAppProvider>
  );
}

export default App;
