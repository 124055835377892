import React, { createContext, useContext, useState, useMemo } from 'react';

const initialState = {
  isOpen: false,
  setIsOpen: (val) => {},
};

const ConfirmDialogContext = createContext(initialState);

export function ConfirmDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const getValue = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen, setIsOpen]
  );

  return (
    <ConfirmDialogContext.Provider value={getValue}>
      {children}
    </ConfirmDialogContext.Provider>
  );
}

export const useConfirmDialogContext = () => useContext(ConfirmDialogContext);
