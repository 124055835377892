import React from 'react';
import Typography from '@mui/material/Typography';
import Wrapper from '../../components/Layout/Wrapper';

interface ErrorFallbackProps {
  error: Error;
}

function ErrorFallback(props: ErrorFallbackProps) {
  const { error } = props;
  return (
    <Wrapper>
      <Typography component="h4" variant="h4">
        Uh oh! Something went wrong&hellip;
      </Typography>
      <Typography component="p" variant="body1">
        Hit the refresh button to try again.
        <br />
        If the error persists, please contact ShapeDigital with the error
        message below
      </Typography>
      <br />
      <pre
        className="error"
        style={{
          padding: '12px 0',
          margin: '12px 0',
        }}
      >
        {error?.message}
      </pre>
    </Wrapper>
  );
}

export default ErrorFallback;
