import React from 'react';
import GlobalStylesBase from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/styles';

function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalStylesBase
      styles={{
        // Headings
        'h1, h2, h3, h4, h5, h6': {
          color: '#133463',
        },
        // Logo
        '.logo': {
          width: '100%',
          maxWidth: '300px',
          marginRight: '24px',
          verticalAlign: 'middle',
          '& .primary': {
            fill: '#f6f5f3', // White
          },
          '& .secondary': {
            fill: '#dd5b30', // Brand Orange
          },
          '@media print': {
            width: '600px',
            '& .primary': {
              fill: '#133463', // Brand Blue
            },
          },
        },
        // Nav
        '.nav--main': {
          '@media print': {
            display: 'none',
          },
        },
        // Containers
        '.wrapper--main': {
          minWidth: '700px',
        },
        '.container--content': {
          maxWidth: theme.breakpoints.values.xl,
          paddingBottom: '50px',
          '@media print': {
            padding: '0!important',
          },
        },
        // DataGrid
        '.table-heading--empty': {
          '& > .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
        '.table-heading--no-separator': {
          '& > .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
        '.MuiDataGrid-columnHeader:focus': {
          outline: 'none !important',
        },
        '.MuiDataGrid-columnHeader:focus-within': {
          outline: 'none !important',
        },
        '.MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '.hide-headers .MuiDataGrid-columnHeaders': {
          display: 'none',
        },
        '.hide-headers .MuiDataGrid-virtualScroller': {
          marginTop: '0 !important',
        },
        '.MuiDataGrid-root': {
          '@media print': {
            border: '0',
          },
        },
        '.MuiDataGrid-row': {
          '@media print': {
            minHeight: '0!important',
            border: '0!important',
          },
        },
        '.MuiDataGrid-cell': {
          '@media print': {
            minHeight: '0!important',
            padding: '5px 0!important',
            border: '0!important',
          },
        },
        '.MuiDataGrid-footerContainer': {
          '@media print': {
            display: 'none',
          },
        },
        // Grids
        '.grid--form': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          rowGap: '16px',
          columnGap: '24px',
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr',
          },
        },
        '.grid--form--modal': {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          rowGap: '16px',
          columnGap: '24px',
          '& h4': {
            gridColumn: '1/-1',
          },
        },
        '.grid--section': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          rowGap: '16px',
          columnGap: '24px',
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
          },
          '@media print': {
            gridTemplateColumns: '1fr 1fr 1fr',
          },
        },
        // Sections
        '.section': {
          marginBottom: '24px',
          '& h2': {
            marginBottom: '10px',
          },
        },
        '.section-highlight': {
          padding: '16px',
          marginBottom: '24px',
          borderRadius: theme.borderRadius.s,
          backgroundColor: theme.palette.bg.sectionHighlight,
          '& h2': {
            marginBottom: '10px',
          },
          '@media print': {
            padding: '0',
          },
        },
        // Page Actions
        '.page-actions': {
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '20px',
          '@media print': {
            marginBottom: '36px',
            display: 'none',
          },
        },
        // Buttons
        '.MuiButton-root': {
          '@media print': {
            display: 'none',
          },
        },
        // Firebase Login
        '#firebaseui_container': {
          backgroundColor: 'transparent !important',
        },
        '.firebaseui-card-header': {
          display: 'none !important',
        },
        '.mdl-card': {
          minHeight: '0 !important',
          boxShadow: 'none !important',
          backgroundColor: 'transparent !important',
        },
        '.firebaseui-card-content': {
          padding: '0 !important',
        },
        '.firebaseui-card-actions': {
          padding: '0 !important',
        },
        '.firebaseui-text': {
          margin: '0 0 20px 0',
        },
        '.mdl-textfield': {
          padding: '0 !important',
        },
        '.mdl-textfield--floating-label': {
          '& .mdl-textfield__label': {
            width: 'auto !important',
            display: 'inline-block !important',
            top: '12px !important',
            right: 'auto !important',
            bottom: 'auto !important',
            left: '16px !important',
            borderRadius: '4px !important',
            backgroundColor: '#fff',
          },
          '.mdl-textfield__input': {
            padding: '10px 14px !important',
            border: '1px solid rgba(0, 0, 0, 0.23) !important',
            borderRadius: '4px !important',
          },
          '&.is-focused': {
            '& .mdl-textfield__label': {
              top: '-6px !important',
              left: '10px !important',
              padding: '0 5px !important',
              color: 'rgba(19, 52, 99, .8) !important',
            },
            '.mdl-textfield__input': {
              borderWidth: '2px !important',
              borderColor: 'rgba(19, 52, 99, .8) !important',
            },
          },
          '&.is-dirty': {
            '& .mdl-textfield__label': {
              top: '-6px !important',
              left: '10px !important',
              padding: '0 5px !important',
            },
          },
        },
        '.firebaseui-error-wrapper': {
          minHeight: '0 !important',
          marginTop: '24px !important',
        },
        '.firebaseui-button': {
          height: 'auto !important',
          padding: '7px 16px !important',
          color: '#fff! important',
          lineHeight: '1.75 !important',
          fontSize: '15px !important',
          letterSpacing: '0.5px !important',
          borderRadius: '4px !important',
          boxShadow: 'none !important',
          backgroundColor: '#dd5b30 !important',
          '&:hover': {
            backgroundColor: '#cd4c22 !important',
          },
        },
        // Dropzone
        '.dropzone--drop-area': {
          padding: '48px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        },
        '.dropzone--file-list': {
          position: 'relative',
          marginTop: '20px',
          border: '1px solid rgba(0, 0, 0, .23)',
          borderRadius: '4px',
          backgroundColor: '#fff',
          zIndex: '100',
        },
        '.dropzone--file-list ul': {
          margin: '0',
          padding: '0',
          listStyle: 'none',
        },
        '.dropzone--file-list li': {
          minHeight: '60px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 10px 0 20px',
        },
        '.dropzone--file-list li + li': {
          borderTop: '1px solid rgba(0, 0, 0, .23)',
        },
        '.dropzone--remove-all': {
          justifyContent: 'flex-end!important',
        },
      }}
    />
  );
}

export default GlobalStyles;
