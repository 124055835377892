import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import Select from '@mui/material/Select';

function ESelect(props: DefaultSelectProps) {
  const {
    disabled,
    required,
    label,
    id,
    name,
    value,
    options,
    onChange,
    error,
    helperText,
  } = props;
  return (
    <FormControl
      margin="dense"
      size="small"
      color="inputs"
      required={required}
      disabled={disabled}
      error={error}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        label={label}
        disabled={disabled}
        required={required}
        onChange={(e) => onChange(e)}
        value={value}
        name={name}
      >
        {options?.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default ESelect;
