import * as Yup from 'yup';

const SubmissionSchema = Yup.object().shape({
  brokerId: Yup.string().required(`Please select a Broker`),
  borrowerFirstName: Yup.string()
    .required(`Please enter a value`)
    .min(2, 'Please enter at least 2 characters'),
  borrowerLastName: Yup.string()
    .required(`Please enter a value`)
    .min(2, 'Please enter at least 2 characters'),
  loanProgram: Yup.string().required(`Please select a value`),
  companyName: Yup.string()
    .required(`Please enter a value`)
    .min(2, 'Please enter at least 2 characters'),
  staffId: Yup.string().required('Please select an Account Executive'),
  bankStatementsType: Yup.string().required(`Please select a value`),
  businessExpenseFactor: Yup.string(),
  businessExpenseFactorPercentage: Yup.number()
    .min(0, 'Please enter a value between 0 and 100')
    .max(100, 'Please enter a value between 0 and 100'),
  profitAndLossExpenseFactorPercentage: Yup.number()
    .min(0, 'Please enter a value between 0 and 100')
    .max(100, 'Please enter a value between 0 and 100'),
  businessNarrative: Yup.string().required(`Please enter a value`),
  businessOwnershipPercentage: Yup.number()
    .required('Please enter a value')
    .min(25, 'Please enter a value between 25 and 100')
    .max(100, 'Please enter a value between 25 and 100'),
  hasThreeMonthsBusinessBankStatements: Yup.string(),
  incompleteReason: Yup.string(),
  comments: Yup.string(),
});

export default SubmissionSchema;
