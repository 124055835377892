import React, { FC, useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FormikSelect from '../FormikSelect';
import FormikTextField from '../FormikTextField';
import { useAuthContext } from '../../context/AuthContext';
import { useBrokerContext } from '../../context/BrokerContext';
import {
  LoanProgramOptions,
  BankStatementTypeOptions,
  BusinessExpenseFactorOptions,
  SubmissionStatuses,
} from '../../shared/constants';
import { FormMode } from '../../shared/enums';

type SubmissionFormProps = {
  mode?: FormMode;
};

const SubmissionForm: FC<SubmissionFormProps> = ({
  mode = FormMode.CREATE,
}) => {
  const {
    claims: { broker: isBroker },
  } = useAuthContext();
  const { brokers } = useBrokerContext();
  const { handleChange, values, setFieldValue, isSubmitting } =
    useFormikContext<Submission>();

  // If we are Admin or Staff and set the broker, set the correct tenantId on the submission
  useEffect(() => {
    if (mode === FormMode.CREATE && values.brokerId) {
      const tId = brokers?.find((it) => it.id === values.brokerId)?.tenantId;
      setFieldValue('tenantId', tId);
    }
  }, [brokers, setFieldValue, values.brokerId, mode]);

  return (
    <Form id="submissionForm">
      {(!isBroker ||
        (values.status !== SubmissionStatuses.REQUIRES_REVIEW.value &&
          values.status !== SubmissionStatuses.COMPLETE.value &&
          values.status !== SubmissionStatuses.IN_PROCESS.value)) && (
        <>
          {!isBroker && (
            <>
              <Box className="section">
                <Typography component="h2" variant="h2">
                  Originator
                </Typography>

                <Box className="grid--form">
                  <FormikSelect
                    id="brokerId"
                    name="brokerId"
                    type="brokerSelect"
                    label="Originator"
                    onChange={handleChange}
                    value={values.brokerId}
                  />
                </Box>
              </Box>

              {mode === FormMode.UPDATE && (
                <Box className="section">
                  {values.status ===
                    SubmissionStatuses.REQUIRES_REVIEW.value && (
                    <>
                      <Typography component="h2" variant="h2">
                        Status
                      </Typography>
                      <Box className="grid--form">
                        <FormikSelect
                          id="status"
                          name="status"
                          label="Submission Status"
                          onChange={handleChange}
                          value={values.status}
                          options={Object.keys(SubmissionStatuses)
                            .filter((it) => {
                              if (
                                values.status ===
                                SubmissionStatuses.REQUIRES_REVIEW.value
                              ) {
                                return it === SubmissionStatuses.COMPLETE.value;
                              }
                              return false;
                            })
                            .map((it) => ({
                              label:
                                SubmissionStatuses[
                                  it as keyof typeof SubmissionStatuses
                                ].label,
                              value:
                                SubmissionStatuses[
                                  it as keyof typeof SubmissionStatuses
                                ].value,
                            }))}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </>
          )}

          {/* Borrower Information */}
          <Box className="section">
            <Typography component="h2" variant="h2">
              Borrower
            </Typography>

            <Box className="grid--form">
              <FormikTextField
                id="borrowerFirstName"
                name="borrowerFirstName"
                label="First Name"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.borrowerFirstName}
                required
              />
              <FormikTextField
                id="borrowerLastName"
                name="borrowerLastName"
                label="Last Name"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.borrowerLastName}
                required
              />
              <FormikSelect
                id="staffId"
                name="staffId"
                type="staffSelect"
                label="Account Executive"
                onChange={handleChange}
                value={values.staffId}
                required
                disabled={isSubmitting}
              />
            </Box>
          </Box>

          {/* Loan Information */}
          <Box className="section">
            <Typography component="h2" variant="h2">
              Loan Information
            </Typography>

            <Box className="grid--form">
              <FormikSelect
                id="bankStatementsType"
                name="bankStatementsType"
                label="Bank Statement Type"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.bankStatementsType}
                options={Object.keys(BankStatementTypeOptions).map((it) => ({
                  label:
                    BankStatementTypeOptions[
                      it as keyof typeof BankStatementTypeOptions
                    ].label,
                  value:
                    BankStatementTypeOptions[
                      it as keyof typeof BankStatementTypeOptions
                    ].value,
                }))}
                required
              />

              <FormikTextField
                id="comments"
                name="comments"
                label="Comments"
                maxRows={4}
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.comments}
                multiline
              />
            </Box>
          </Box>

          {/* Business Information */}
          <Box className="section">
            <Typography component="h2" variant="h2">
              Business Information
            </Typography>

            <Box className="grid--form">
              <FormikTextField
                id="companyName"
                name="companyName"
                label="Company Name"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.companyName}
                required
              />
              <FormikTextField
                id="businessOwnershipPercentage"
                name="businessOwnershipPercentage"
                label="Business Ownership Percentage"
                type="number"
                inputProps={{ min: 25, max: 100 }}
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.businessOwnershipPercentage}
                required
              />{' '}
              <FormikSelect
                id="loanProgram"
                name="loanProgram"
                label="Loan Program"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.loanProgram}
                options={Object.keys(LoanProgramOptions).map((it) => ({
                  label:
                    LoanProgramOptions[it as keyof typeof LoanProgramOptions]
                      .label,
                  value:
                    LoanProgramOptions[it as keyof typeof LoanProgramOptions]
                      .value,
                }))}
                required
              />
              {values.bankStatementsType ===
                BankStatementTypeOptions.PERSONAL.value && (
                <FormControl>
                  <FormLabel id="formLabel">
                    2 Months Business Bank Statements Available
                  </FormLabel>
                  <RadioGroup
                    name="hasThreeMonthsBusinessBankStatements"
                    aria-labelledby="formLabel"
                    row
                    onChange={(e) => {
                      setFieldValue(
                        'hasThreeMonthsBusinessBankStatements',
                        e.target.value === 'true'
                      );
                    }}
                    value={values.hasThreeMonthsBusinessBankStatements}
                  >
                    <FormControlLabel
                      name="hasThreeMonthsBusinessBankStatements"
                      value
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      name="hasThreeMonthsBusinessBankStatements"
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <FormikSelect
                id="businessExpenseFactor"
                name="businessExpenseFactor"
                label="Business Expense Factor"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.businessExpenseFactor}
                options={Object.keys(BusinessExpenseFactorOptions)
                  .filter(
                    (name) =>
                      name !== BusinessExpenseFactorOptions.FIXEDEXPENSE.value
                  )
                  .map((it) => ({
                    label:
                      BusinessExpenseFactorOptions[
                        it as keyof typeof BusinessExpenseFactorOptions
                      ].label,
                    value:
                      BusinessExpenseFactorOptions[
                        it as keyof typeof BusinessExpenseFactorOptions
                      ].value,
                  }))}
              />
              {values.businessExpenseFactor ===
                BusinessExpenseFactorOptions.TAXORCPA.value && (
                <FormikTextField
                  id="businessExpenseFactorPercentage"
                  name="businessExpenseFactorPercentage"
                  label="Business Expense Factor Percentage"
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  value={values.businessExpenseFactorPercentage}
                  required
                />
              )}
              {values.businessExpenseFactor ===
                BusinessExpenseFactorOptions.THIRDPARTYPL.value && (
                <FormikTextField
                  id="profitAndLossExpenseFactorPercentage"
                  name="profitAndLossExpenseFactorPercentage"
                  label="P&amp;L Expense Factor Percentage"
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  value={values.profitAndLossExpenseFactorPercentage}
                  required
                />
              )}
              <FormikTextField
                id="businessNarrative"
                name="businessNarrative"
                label="Business Narrative"
                required
                multiline
                maxRows={4}
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.businessNarrative}
              />
            </Box>
          </Box>
        </>
      )}
      {isBroker &&
        (values.status === SubmissionStatuses.IN_PROCESS.value ||
          values.status === SubmissionStatuses.REQUIRES_REVIEW.value ||
          values.status === SubmissionStatuses.COMPLETE.value) && (
          <Box className="section">This submission cannot be edited</Box>
        )}
    </Form>
  );
};

export default SubmissionForm;
