import React, { createContext, useContext, useMemo } from 'react';
import { collection, where, query, DocumentData } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

type AdminValue = {
  admin: DocumentData[];
  isLoading: boolean;
};

const initialState: AdminValue = {
  admin: [],
  isLoading: false,
};

const AdminContext = createContext(initialState);

type AdminProviderProps = {
  children: React.ReactNode;
};

export function AdminProvider(props: AdminProviderProps) {
  const { children } = props;

  const db = useFirestore();
  const constraints = useMemo(() => {
    const res = [where('isDeleted', '==', false), where('type', '==', 'Admin')];
    return res;
  }, []);

  const qAdmin = query(collection(db, 'users'), ...constraints);

  const { status, data } = useFirestoreCollectionData(qAdmin, {
    idField: 'id',
  });

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: AdminValue = useMemo(
    () => ({
      admin: data,
      isLoading,
    }),
    [isLoading, data]
  );

  return (
    <AdminContext.Provider value={getValue}>{children}</AdminContext.Provider>
  );
}

export const useAdminContext = () => useContext(AdminContext);
