import React, { FC, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Loading from '../Loading';
import { useSubmissionFilesContext } from '../../context/SubmissionFilesContext';

type ArchiveSubmissionModalProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};

const ArchiveSubmissionModal: FC<ArchiveSubmissionModalProps> = ({
  setIsOpen,
  isOpen,
}) => {
  const db = useFirestore();
  const { id } = useParams();
  const { files: fileList } = useSubmissionFilesContext();

  const [isLoading, setIsLoading] = useState(false);

  const doArchive = useCallback(async () => {
    setIsLoading(true);
    try {
      const docRef = doc(collection(db, 'submissions'), id as string);
      const batch = writeBatch(db);
      batch.update(docRef, {
        isArchived: true,
        updatedAt: DateTime.local().setZone('utc').toISO(),
      });
      fileList.forEach((f) => {
        const fDR = doc(collection(db, 'files'), f.id);
        batch.update(fDR, {
          isArchived: true,
          updatedAt: DateTime.local().setZone('utc').toISO(),
        });
      });
      await batch.commit();
      setIsLoading(false);
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [id, setIsOpen, fileList, db]);

  return (
    <Dialog open={isOpen} maxWidth="xs" scroll="paper" fullWidth>
      <DialogTitle color="status.primary">Archive Submission</DialogTitle>
      <DialogContent>
        <>
          <Typography variant="h4" component="h4">
            Are you sure you want to Archive this submission?
          </Typography>
          {isLoading && <Loading />}
        </>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={isLoading}
          onClick={() => doArchive()}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveSubmissionModal;
