import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

function Modal(props) {
  const {
    title,
    isOpen,
    content,
    cancelText,
    confirmText,
    onConfirm,
    onCancel,
    isDisabled,
    fullWidth,
    maxWidth,
    status,
  } = props;
  return (
    <Dialog
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll="paper"
      status={status}
    >
      <DialogTitle color={`status.${status}`}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions
        sx={{
          padding: '10px 24px 20px 24px',
        }}
      >
        <Button color="secondary" onClick={() => onCancel()}>
          {cancelText}
        </Button>
        <Button
          variant="contained"
          color={status}
          disableElevation
          disabled={isDisabled}
          onClick={() => onConfirm()}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  status: PropTypes.oneOf(['error', 'warning', 'success', 'primary']),
};

Modal.defaultProps = {
  isDisabled: false,
  fullWidth: true,
  maxWidth: 'sm',
  status: 'primary',
};

export default Modal;
