import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { collection, where, query } from 'firebase/firestore';
import { httpsCallableFromURL } from 'firebase/functions';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from 'reactfire';
import { useAuthContext } from './AuthContext';

const { REACT_APP_GET_FILE_URL_FN_NAME } = process.env;

type SubmissionFilesValue = {
  files: SubmissionFile[];
  isLoading: boolean;
  getDownloadURL: (id: string) => Promise<string | null | undefined>;
};

const initialState: SubmissionFilesValue = {
  files: [],
  isLoading: false,
  getDownloadURL: async () => null,
};

const SubmissionFilesContext = createContext(initialState);

type SubmissionFilesProviderProps = {
  children: React.ReactNode;
};

type GetDLUrlResult = {
  url?: string;
  error?: string;
  status: boolean;
};

type FnCallableResult = {
  data: GetDLUrlResult;
};

export function SubmissionFilesProvider(props: SubmissionFilesProviderProps) {
  const { children } = props;
  const db = useFirestore();
  const fns = useFunctions();
  const { id } = useParams();
  const {
    claims: { broker: isBroker, tenantId },
  } = useAuthContext();

  const constraints = useMemo(() => {
    const res = [
      where('submissionId', '==', id),
      where('isArchived', '==', false),
    ];
    if (isBroker) {
      res.push(where('tenantId', '==', tenantId));
    }
    return res;
  }, [isBroker, tenantId, id]);

  const q = query(collection(db, 'files'), ...constraints);

  const { status, data } = useFirestoreCollectionData(q, {
    idField: 'id',
  });

  const fn = httpsCallableFromURL(
    fns,
    REACT_APP_GET_FILE_URL_FN_NAME as string
  );

  const getDownloadURL = useCallback(
    async (fileId: string) => {
      const file = data.find((it) => it.id === fileId);
      if (!file) return null;
      const result = await fn({
        filePath: `${file.tenantId}/${id}/${file.name}`,
        expirationTimeInMinutes: 60,
      });
      if (result && (result as unknown as FnCallableResult)) {
        return (result.data as GetDLUrlResult).url;
      }
      return null;
    },
    [data, fn, id]
  );

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getValue: SubmissionFilesValue = useMemo(
    () => ({
      files:
        data?.map((it) => {
          return it as SubmissionFile;
        }) ?? [],
      isLoading,
      getDownloadURL,
    }),
    [data, isLoading, getDownloadURL]
  );

  return (
    <SubmissionFilesContext.Provider value={getValue}>
      {children}
    </SubmissionFilesContext.Provider>
  );
}

export const useSubmissionFilesContext = () =>
  useContext(SubmissionFilesContext);
